import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { LogErrors } from './../../../utilities/LogErrors'
import { useLanguage } from './../../../utilities/LanguageContext'

//Funciones personalizadas
import { ValidateSession } from './../../../utilities/BL/Sessions'
import { SetDefaultWebTheme } from './../../../utilities/BL/Settings'
import { GetCourses as BLGetCourses } from './../../../utilities/BL/Student/Courses'
import { ResetCourseToShow, SaveCenterToShow } from './../../../utilities/BL/Navigation'
import { GetResults } from './../../../utilities/BL/Student/Results'

//Componentes comunes generales
import { Header } from './../../Header/Header'
import { Section } from './../Section'
import { Loading } from './../../Common/Loading'

//Componentes del proyecto
import { Options } from './Options'
//import { Subdashboard } from './Subdashboard'
import { Tile } from './Tile'
import { GoToTop } from './../../Common/GoToTop'
import { Button } from './../../Common/Button'
import { Result } from './Result'

//Ver cómo mostrar los mensajes de error. Conforme salga un error.


const Dashboard = () => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)
    const [session, setSession] = useState({})
    
    const [theme, setTheme] = useState({})
    //const [centerSelected, setCenterSelected] = useState(null)
    //const [roleSelected, setRoleSelected] = useState(null)

    const [courses, setCourses] = useState(null)
    const [approvedCourses, setApprovedCourses] = useState(null)
    const [results, setResults] = useState(null)


    const LoadPage = async (sessionInfo) => {
        try {
            //Establecer el tema a la web
            const response = await SetDefaultWebTheme()
            setTheme(response.data)
            
            
            const currentSessionInfo = await ResetCourseToShow(sessionInfo)

            if (currentSessionInfo.success) {
                setSession(currentSessionInfo.data)
                
                //Mostrar componente para elegir el Centro y Rol
                // if (sessionInfo.user.centers.length === 1) {
                //     setCenterSelected(sessionInfo.user.centers.id)

                //     if (sessionInfo.user.centers[0].roles.length === 1) {
                //         setRoleSelected(sessionInfo.user.centers[0].roles.id)
                //     }
                // }

                // debugger
                // setCenterSelected(parseInt(sessionInfo.user.centers[0].id))
                // setRoleSelected(parseInt(sessionInfo.user.centers[0].roles[0].id))
                // debugger

                //Validación temporal, falta agregar validaciones en caso de haber varios centros y roles
                let centerSelected
                let roleSelected

                if (sessionInfo.user.centers.length === 1) {
                    centerSelected = parseInt(sessionInfo.user.centers[0].id)

                    if (sessionInfo.user.centers[0].roles.length === 1) {
                        roleSelected = parseInt(sessionInfo.user.centers[0].roles[0].id)
                    }
                }

                const subresponse = await SaveCenterToShow(currentSessionInfo.data, centerSelected)
                
                if (subresponse.success) {
                    await GetCourses(subresponse.data, centerSelected, roleSelected)
                    
                    const results = await GetResults(sessionInfo)
                    if (results.success) { setResults(results.data) }

                    setIsLoading(false)
                } else {
                    console.log('No se pudo guardar correctamente el centro seleccionado')
                }


            } else {
                //Mostrar mensaje de que no se puedo obtener la info o el error
                console.log(currentSessionInfo.message) //Mensaje temporal
            }
        } catch (error) {
            await LogErrors('LoadPage - Dashboard', error)
        }
    }

    const GetCourses = async (sessionInfo, centerSelected, roleSelected) => {
        try {
            const response = await BLGetCourses(sessionInfo, centerSelected, roleSelected)

            if (response.success) {
                setSession(response.data)

                setCourses(response.data.user.courses.myCourses)
                setApprovedCourses(response.data.user.courses.myApprovedCourses)
            } else {
                //Mostrar mensaje de que no se puedo obtener la info o el error
                console.log("No se pudo obtener la info") //Mensaje temporal
            }
        } catch (error) {
            await LogErrors('GetCourses - Dashboard', error)
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Función para obtener la configuración, una vez que termine de cargarse el componente
     */
    useEffect(() => {
        async function Validate() {
            const tmpSession = await ValidateSession()
            setSession(tmpSession.data)
    
            if (tmpSession.success) {
                if (tmpSession.data.user.isConfirmed) {
                    await LoadPage(tmpSession.data)
                } else {
                    navigate('/confirm')
                }
            } else {
                navigate('/')
            }
        }
        Validate()
    }, []) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar


    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Header theme={theme} />

                    <main className='Container SDashboard'>
                        <p className='SDashboard-Welcome'>{`${translate('labels.dashboard.courses.welcome')} ${session.user.name} ${session.user.lastName}`}</p>

                        <Section textSection={translate('labels.dashboard.courses.title')}>
                            {courses && (
                                courses.length === 0 ? (
                                    <p>{translate('labels.dashboard.courses.empty')}</p>
                                ) : (
                                    <Tile elements={courses} session={session} setSession={setSession} />
                                )
                            )}
                        </Section>

                        {approvedCourses && (
                            approvedCourses.length === 0 ? (
                                <p>{translate('labels.dashboard.approvedCourses.empty')}</p>
                            ) : (
                                <Section textSection={translate('labels.dashboard.approvedCourses.title')} classNameSection={'Section-Separation'}>
                                    <Tile elements={approvedCourses} session={session} setSession={setSession} />
                                </Section>
                            )
                        )}

                        {results && (
                            results.evaluations.length > 0 && (
                                <Section textSection="Mis resultados" classNameSection={'Section-Separation'}>
                                    <div className='Tiles HideMoveButton'>
                                        {/* Botón mover */}
                                        <Button className={'Tiles-ButtonMoveTile'} iconClass={'bi bi-three-dots-vertical'} />

                                        <Result />
                                    </div>
                                </Section>
                            )
                        )}
                    </main>

                    <GoToTop />
                </>



                // <Subdashboard
                //     session={session}
                //     setSession={setSession}
                //     theme={theme}
                //     setShowQuickAccess={setShowQuickAccess}
                //     centerSelected={centerSelected}
                //     roleSelected={roleSelected}
                // />



                // <> 
                //     {centerSelected === '' && (
                //         <Options
                //             elements={current.user.centers}
                //             textButton={translate('labels.dashboard.center.deselected')}
                //             setState={setCenterSelected}
                //         />
                //     )}

                //     {centerSelected !== '' && roleSelected === '' && (
                //         <Options
                //             elements={current.user.centers[centerSelected].roles}
                //             textButton={translate('labels.dashboard.role.deselected')}
                //             setState={setRoleSelected}
                //         />
                //     )}

                //     {centerSelected !== '' && roleSelected !== '' && (
                //         <Subdashboard
                //             current={session}
                //             setCurrent={setSession}
                //             theme={theme}
                //             setTheme={setTheme}
                //             setFontSize={setFontSize}
                //             setShowQuickAccess={setShowQuickAccess}
                //             centerSelected={centerSelected}
                //             roleSelected={roleSelected}
                //         /> //courses={courses} approvedCourses={approvedCourses}
                //     )}
                // </>
            )}
        </>
    )
}

export { Dashboard };