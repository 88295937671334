
//Agregar multiidioma

const Loading = () => {
    try {
        return (
            <div className="Loading">
                <p className="spinner-border text-light" role="status"><span className="visually-hidden">Cargando...</span></p>
                <p>Cargando...</p>
            </div>
        )
    } catch (error) {
        console.log(error)
    }
}

export { Loading };