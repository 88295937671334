import { useState, useEffect } from 'react'
import { useLanguage } from './../../../utilities/LanguageContext'
import { LogErrors } from './../../../utilities/LogErrors'

//Componentes personalizados del proyecto
import { Concepts } from './Concepts/Concepts'
import { Quizzes } from './Quizzes/Quizzes'
import { Videos } from './Videos/Videos'
import { Challenges } from './Challenges/Challenges'

//Componentes comunes generales
import { GoToTop } from './../../Common/GoToTop'
import { GoToBack } from './../../Common/GoToBack'

//Funciones personalizadas
import { UpdateQuickAccessOfCourse } from './../../../utilities/BL/Student/Instruments'


const Teaching = ({ session, setSession, instrument, changeLearningInstrumentContent, topicName }) => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto

    const [activeComponent, setActiveComponent] = useState('Concept') // Estado para controlar el componente activo
    const [currentTopicIndex, setCurrentTopicIndex] = useState(0) // Índice del tema actual
    const [topics, setTopics] = useState([]) // Lista de temas filtrados


    useEffect(() => {
        // Extraer todos los temas disponibles con type: 2
        const availableTopics = session.courses.blocks.flatMap(block =>
            block.items.filter(item => item.type === 2)
        )
    
        setTopics(availableTopics)
    
        // Encontrar la posición del tema actual
        const currentIndex = availableTopics.findIndex(item => item.id === session.navigation.instrument.id)
        setCurrentTopicIndex(currentIndex)
    }, [session])

    const GoToPreviousTopic = async () => {
        try {
            if (currentTopicIndex > 0) {
                const previousTopic = topics[currentTopicIndex - 1]
                UpdateSessionAndNavigate(previousTopic)
            }
        } catch (error) {
            await LogErrors('GoToPreviousTopic - Teaching', error)
        }
    }
    
    const GoToNextTopic = async () => {
        try {
            if (currentTopicIndex < topics.length - 1) {
                const nextTopic = topics[currentTopicIndex + 1]
                UpdateSessionAndNavigate(nextTopic)
            }
        } catch (error) {
            await LogErrors('GoToNextTopic - Teaching', error)
        }
    }

    const UpdateSessionAndNavigate = async (newTopic) => {
        try {
            // Actualizar el estado de navegación en la sesión
            //const response = await UpdateQuickAccessOfCourse(session, newTopic.id)
            const response = await UpdateQuickAccessOfCourse(session, newTopic) //newTopic.id

            if (response.success) {
                // setSession(response.data) // Actualizar la sesión
                // await changeLearningInstrumentContent(response.data)
                const updatedSession = { ...response.data } // Clonar el objeto para forzar re-render
                setSession(updatedSession)
                await changeLearningInstrumentContent(updatedSession)
            } else {
                console.log('Error al actualizar la sesión')
            }
        } catch (error) {
            await LogErrors('UpdateSessionAndNavigate - Teaching', error)
        }
    }


    return (
        <>
            <main className='Container-FullHeight Teaching'>
                <div className="container">
                    <GoToBack url={'/course'} />
                </div>
                
                <div className='Teaching-Container'>
                    {/* {activeComponent === 'Concept' && <Concepts topicName={session.navigation.instrument.name} instrument={instrument} /> } */}
                    {activeComponent === 'Concept' && <Concepts topicName={topicName} instrument={instrument} />}
                    {activeComponent === 'Quiz' && <Quizzes instrument={instrument} />}
                    {activeComponent === 'Video' && <Videos session={session} setSession={setSession} instrument={instrument} />}
                    {activeComponent === 'Challenge' && <Challenges instrument={instrument} />}
                </div>
            </main>

            <aside className='Teaching-Menu'>
                <div className='Teaching-Menu-Container'>
                    {/* Botón Previous (solo si hay un tema anterior) */}
                    {currentTopicIndex === 0 ? (
                        <div className='Teaching-Menu-Button-Disabled'>
                            <i className='bi bi-arrow-left Teaching-Menu-Button-Icon'></i>
                            <span className='Teaching-Menu-Button-Disabled-Text'>{translate('labels.viewer.teaching.previous')}</span>
                        </div>
                    ) : (
                        <button type='button' className='Teaching-Menu-Button' onClick={GoToPreviousTopic}>
                            <i className='bi bi-arrow-left Teaching-Menu-Button-Icon'></i>
                            <span className='Teaching-Menu-Button-Text'>{translate('labels.viewer.teaching.previous')}</span>
                        </button>
                    )}

                    <button type='button' className={`Teaching-Menu-Button ${activeComponent === 'Concept' ? 'Teaching-Menu-Bottom-Active' : ''}`} onClick={() => setActiveComponent('Concept')}>
                        <i className='bi bi-file-earmark-text Teaching-Menu-Button-Icon'></i><span className='Teaching-Menu-Button-Text'> {translate('labels.viewer.teaching.concept')}</span>
                    </button>
                    <button type='button' className={`Teaching-Menu-Button ${activeComponent === 'Quiz' ? 'Teaching-Menu-Bottom-Active' : ''}`} onClick={() => setActiveComponent('Quiz')}>
                        <i className='bi bi-ui-radios Teaching-Menu-Button-Icon'></i><span className='Teaching-Menu-Button-Text'> {translate('labels.viewer.teaching.quizz')}</span>
                    </button>
                    <button type='button' className={`Teaching-Menu-Button ${activeComponent === 'Video' ? 'Teaching-Menu-Bottom-Active' : ''}`} onClick={() => setActiveComponent('Video')}>
                        <i className='bi bi-play-btn Teaching-Menu-Button-Icon'></i><span className='Teaching-Menu-Button-Text'> {translate('labels.viewer.teaching.video')}</span>
                    </button>
                    <button type='button' className={`Teaching-Menu-Button ${activeComponent === 'Challenge' ? 'Teaching-Menu-Bottom-Active' : ''}`} onClick={() => setActiveComponent('Challenge')}>
                        <i className='bi bi-layout-split Teaching-Menu-Button-Icon'></i><span className='Teaching-Menu-Button-Text'> {translate('labels.viewer.teaching.challenge')}</span>
                    </button>

                    {/* Botón Next (solo si hay un tema siguiente) */}
                    {currentTopicIndex < topics.length - 1 ? (
                        <button type='button' className='Teaching-Menu-Button' onClick={GoToNextTopic}>
                            <span className='Teaching-Menu-Button-Text'>{translate('labels.viewer.teaching.next')}</span>
                            <i className='bi bi-arrow-right Teaching-Menu-Button-Icon'></i>
                        </button>
                    ) : (
                        <div className='Teaching-Menu-Button-Disabled'>
                            <span className='Teaching-Menu-Button-Disabled-Text'>{translate('labels.viewer.teaching.next')}</span>
                            <i className='bi bi-arrow-right Teaching-Menu-Button-Icon'></i>
                        </div>
                    )}
                </div>
            </aside>

            <GoToTop />
        </>
    )
}

export { Teaching };