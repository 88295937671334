import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LogErrors } from './../../utilities/LogErrors'

//Funciones comunes
import { Confirms as BLConfirms } from '../../utilities/BL/Confirms'
import { ValidateSession } from './../../utilities/BL/Sessions'
import { useLanguage } from './../../utilities/LanguageContext'

//Componentes comunes
import { Header } from './../Header/Header'
import { Input } from './../Login/Fields/Input'
//import { Button } from './../Common/Button'
import { Loading } from './../Common/Loading'


const Confirm = () => {
  const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
  const navigate = useNavigate()

  //Estados
  const [formData, setFormData] = useState({ name: '', lastName: '', nickname: '', email: '', password: '' }) // Estado que maneja la info del formulario
  const [errors, setErrors] = useState({}) // Estado que maneja los errores
  const [isSubmitted, setIsSubmitted] = useState(false) // Estado que controla si se ha enviado, o no, el formulario
  
  const [isLoading, setIsLoading] = useState(true)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  //const [session, setSession] = useState({})
  const [theme, setTheme] = useState({})


  // Referencias
  const refs = {
    name: useRef(null),
    lastName: useRef(null),
    nickname: useRef(null),
    email: useRef(null),
    password: useRef(null)
  }

  
  //--- Funciones personalizadas ---//
  const LoadPage = async (sessionInfo) => {
    try {
      setTheme(sessionInfo.user.userTheme)

      formData.name = sessionInfo.user.name
      formData.lastName = sessionInfo.user.lastName
      setFormData(formData)
    } catch (error) {
      await LogErrors('LoadPage - Confirm', error)
    } finally {
      setIsLoading(false)
    }
  }

  const Confirm = async (e) => {
    try {
      e.preventDefault()
      setIsButtonLoading(true)

      const session = await ValidateSession()
      
      if (session.success) {
        if (session.data.user.isConfirmed) {
          navigate('/dashboard')
        } else {
          setIsSubmitted(true) // Marcar el formulario como enviado
          
          const response = await BLConfirms(session.data, formData) // Llama a la función Confirm de BL
          
          if (response.success) {
            navigate('/dashboard')
          } else {
            // Muestra los errores si los hay
            setErrors(response.errors)

            // Hacer focus en el campo correspondiente
            for (const key in response.errors) {
              if (response.errors[key]) {
                refs[key].current.focus()
                break
              }
            }
          }
        }
      } else {
        navigate('/')
      }
    } catch (error) {
      await LogErrors('Confirm - Confirm', error)
    } finally {
      setIsButtonLoading(false)
    }
  }
  //--- Funciones personalizadas ---//

  //--- Eventos de la página web ---//
  useEffect(() => {
    async function Validate() {
      const tmpSession = await ValidateSession()
      //setSession(tmpSession.data)
      
      if (tmpSession.success) {
        if (tmpSession.data.user.isConfirmed) {
          navigate('/dashboard')
        } else {
          await LoadPage(tmpSession.data)
        }
      } else {
          navigate('/')
      }
    }
    Validate()
  }, []) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar
  //--- Eventos de la página web ---//

  //--- Eventos de formulario ---//
  /**
   * Función que se ejecuta cuando se realiza un cambio en los campos del formulario.
   * @param {Event} e
   */
  const InputChange = async (e) => {
    try {
      const { id, value } = e.target

      setFormData({
        ...formData,
        [id]: value,
      })
    } catch (error) {
      await LogErrors('InputChange - Confirm', error)
    }
  }

  /**
   * Función que se ejecuta cuando se presiona la tecla Enter o se presiona el botón del formulario
   * @param {Event} e
   */
  const FormSubmit = async (e) => {
    try {
      Confirm(e)
    } catch (error) {
      await LogErrors('FormSubmit - Confirm', error)
    }
  }
  //--- Eventos de formulario ---//

  return (
    <>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {/* <Header text={`${session.user.name} ${session.user.lastName}`} theme={session.user.userTheme} /> */}
            <Header text="Confirma tus datos" theme={theme} />

            <main className="container Container-FullHeight Container-FlexContent">
              <div className="Confirm">
                <h1 className="Confirm-Title">{translate('labels.confirm.title')}</h1>

                <form className="Confirm-Form" onSubmit={FormSubmit}>
                  <Input
                    type="text"
                    id="name"
                    value={formData.name}
                    onChange={InputChange}
                    placeholder={translate('labels.confirm.name')}
                    error={isSubmitted ? errors.name : null}
                    isSubmitted={isSubmitted}
                    ref={refs.name}
                  />

                  <Input
                    type="text"
                    id="lastName"
                    value={formData.lastName}
                    onChange={InputChange}
                    placeholder={translate('labels.confirm.lastName')}
                    error={isSubmitted ? errors.lastName : null}
                    isSubmitted={isSubmitted}
                    ref={refs.lastName}
                  />

                  <Input
                    type="text"
                    id="nickname"
                    value={formData.nickname}
                    onChange={InputChange}
                    placeholder={translate('labels.confirm.nickname')}
                    error={isSubmitted ? errors.nickname : null}
                    isSubmitted={isSubmitted}
                    ref={refs.nickname}
                  />

                  <Input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={InputChange}
                    placeholder={translate('labels.confirm.email')}
                    error={isSubmitted ? errors.email : null}
                    isSubmitted={isSubmitted}
                    ref={refs.email}
                  />

                  <Input
                    type="password"
                    id="password"
                    value={formData.password}
                    onChange={InputChange}
                    placeholder={translate('labels.confirm.password')}
                    error={isSubmitted ? errors.password : null}
                    isSubmitted={isSubmitted}
                    ref={refs.password}
                  />


                  {isButtonLoading ? (
                    <button type='button' className='Confirm-Form-Button Confirm-Form-Button-Disabled'>{translate('labels.confirm.loadingSubmit')}</button>
                  ) : (
                    <button type='submit' className='Confirm-Form-Button'>{translate('labels.confirm.submit')}</button>
                  )}

                  {errors.general && <p className="Confirm-Form-Error-Message">{errors.general}</p>}
                </form>
              </div>
            </main>
          </>
      )}
    </>
  )
}

export { Confirm };