import { GoToBack } from './../Common/GoToBack'


const Download = () => {
    return (
        <>
            <header className="container Header">
                <div className="Header-Side1">
                    <div className="Header-Brand">
                        <img src="./img/brand/Ztudia-Logotipo-Negro-66x26.png" alt="Logotipo-Ztudia" className="Header-Image" />
                    </div>
                </div>

                <div className="Header-Side2"></div>
                
                <div className="Header-Side3"></div>
            </header>

            <main className="Download container">
                <GoToBack url={'/'} />
                
                <img src="./img/brand/Ztudia-Logotipo-Negro-350x135.png" alt="Logotipo-Ztudia" className="Download__Image" />
                <h1 className="Download__Title">Descarga la solución Ztudia</h1>
                <a className="Download__Link" href="./files/download/ztudiaapp.zip" download><i class="bi bi-download"></i> Descargar ahora</a>
            </main>
        </>
    )
}

export { Download };