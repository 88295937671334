import { LogErrors } from './../../LogErrors'

import { GetResults as DLGetResults } from './../../DL/Student/Results'
import { UpdateSessionInfo } from './../Sessions'


const GetResults = async (session) => {
    try {
        //Validar que exista la info del usuario en el session storage
        if (!sessionStorage.getItem('userData')) {
            return { success: false, message: '' } //En este caso borraron los datos; así que le muestro mensaje indicando que su sesión ha expirado.
        }
    
        //Obtener la info del usuario desde el session storage
        const userData = JSON.parse(sessionStorage.getItem('userData'))
        const response = await DLGetResults(session.token)

        if (response.success) {
            // Guardar info de evaluaciones en info de sesión
            if (response.data.evaluations !== undefined && response.data.evaluations !== null) {
                if (response.data.evaluations.length > 0) {
                    userData.evaluations = response.data.evaluations
        
                    await UpdateSessionInfo(userData)
                }
            }

            return { success: true, data: response.data }
        } else {
            return { success: false, message: response.message }
        }
    } catch (error) {
        await LogErrors('GetResults BL Results', error)
    }
}

export { GetResults };