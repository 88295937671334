import React, { useState, forwardRef } from 'react';

const Input = forwardRef(({ type, id, value, onChange, placeholder, error, isSubmitted }, ref) => {
  const [showPassword, setShowPassword] = useState(false) // Estado que controla si se debe mostrar, o no, la contraseña

  /**
   * Función que actualiza el estado que controla si se debe mostrar, o no, la contraseña
   */
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="loginForm-Input-Container">
      <input
        type={showPassword ? 'text' : type}
        // className={`loginForm-Input ${error ? 'loginForm-Input-Error' : (!error && value.trim() !== '') ? 'loginForm-Input-Success' : ''}`}
        //className={`loginForm-Input ${isSubmitted && ( error ? 'loginForm-Input-Error' : 'loginForm-Input-Success' )}`}
        className={`loginForm-Input ${isSubmitted && ( error ? 'loginForm-Input-Error' : '' )}`}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        ref={ref} // Propiedad para asignar este campo al objeto del componente padre para saber en qué campo mostrar el mensaje de error
      />


      {/* Contenedor del ícono para mostrar/ocultar contraseña */}
      <span className="loginForm-Input-Icon-Container">
        {
          /* Ícono del campo contraseña para mostrar/ocultar contraseña */
          type === 'password' && (
            <i className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`} onClick={togglePasswordVisibility} />
          )
        }

        {/* {
          //Ícono de validación (solo si se ha enviado el formulario)
          isSubmitted && (
            <i className={`bi ${error ? 'bi-x-circle-fill text-danger' : 'bi-check-circle-fill text-success'}`} />
          )
        } */}
      </span>


      {
        /* Mensaje de error */
        error && (
          <div className="loginForm-Input-Error-Message">{error}</div>
        )
      }
    </div>
  )
})
  
export { Input };


{/* Ícono de check o equis */}
{/* {hasValue && type !== 'password' && ( // Ícono de validación para otros campos 
  <i 
    className={`bi ${error ? 'bi-x-circle-fill text-danger' : 'bi-check-circle-fill text-success'}`} 
  />
)} */}