import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { LogErrors } from './../../utilities/LogErrors'
import { useLanguage } from './../../utilities/LanguageContext'

//Componentes comunes generales
import { ProgressBar } from './../Common/ProgressBar'

//Funciones personalizadas del proyecto
import { SaveInstrumentToShow } from './../../utilities/BL/Navigation'
import { UpdateQuickAccessOfCourse } from './../../utilities/BL/Student/Instruments'


//Agregar multiidioma


const Blocks = ({ blocks, session, setSession, quickaccessName }) => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()

    // Estado para manejar qué bloques están activos
    const [activeBlocks, setActiveBlocks] = useState({})
    // Estado para manejar qué objetivos están visibles
    const [showObjectives, setShowObjectives] = useState({})
    // Referencias para cada bloque
    const blockRefs = useRef([])
    //Estado para rastrear el bloque seleccionado
    const [blockToScroll, setBlockToScroll] = useState(null)
    // Referencias para cada tema
    const topicRefs = useRef({})


    /**
     * Sincroniza el primer bloque al cargar si `quickaccessName` está definido
     */
    useEffect(() => {
        if (quickaccessName === undefined) {
            setActiveBlocks({ 0: true }) // Establece el primer bloque como activo
        }
    }, [quickaccessName])

    /**
     * Desplazar la ventana al bloque abierto
     */
    useEffect(() => {
        if (blockToScroll !== null && blockRefs.current[blockToScroll]) {
            blockRefs.current[blockToScroll].scrollIntoView({
                behavior: 'smooth', // Animación suave
                block: 'start', // Alinear al inicio del bloque
            })
            setBlockToScroll(null) // Restablecer el estado después del desplazamiento
        }
    }, [blockToScroll]) // Ejecutar cuando se cambia el bloque a desplazar

    /**
     * Función para abrir el bloque
     * @param {integer} index 
     */
    const ToggleList = (index) => {
        // Actualizar el estado para mostrar el bloque actual
        setActiveBlocks(() => ({
            [index]: !activeBlocks[index] // Cambia el estado solo para este bloque
        }))
        //setBlockToScroll(index) // Marcar el bloque que debe desplazarse
        setShowObjectives(() => ({})) // Oculta todos los objetivos
    }

    /**
     * Función para mostrar u ocultar el objetivo del bloque
     * @param {integer} index 
     */
    const ToggleShowObjective = (index) => {
        setShowObjectives((prevState) => ({
            ...prevState,
            [index]: !prevState[index] // Invierte el estado del objetivo actual
        }))
    }

    /**
     * Desplazar la ventana al tema que se había hecho clic antes de pasar a la página Viewer
     */
    useEffect(() => {
        if (session.navigation.instrument !== undefined && session.navigation.instrument.id) {
            blocks.forEach((block, index) => {
                if (block.items.some(item => item.id === parseInt(session.navigation.instrument.id))) {
                    setActiveBlocks(prevState => ({
                        ...prevState,
                        [index]: true // Abre solo el bloque que contiene el tema seleccionado
                    }))

                    setTimeout(() => {
                        if (topicRefs.current[session.navigation.instrument.id]) {
                            topicRefs.current[session.navigation.instrument.id].scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                            })
                        }
                    }, 500) // Esperamos 500ms para asegurarnos de que el bloque ya está visible    
                }
            })
        }
    }, [blocks])

    const GoToInstrument = async (instrument) => {
        try {
            const response = await SaveInstrumentToShow(session, instrument)

            if (response.success) {
                const subresponse = await UpdateQuickAccessOfCourse(session, instrument) //instrument.id
                if (subresponse.success) { setSession(subresponse.data) }

                navigate('/viewer')
            } else {
                // Mostrar mensaje de que no se pudo obtener la info o el error
                console.log('No se pudo obtener la info') // Mensaje temporal
            }
        } catch (error) {
            await LogErrors('GoToInstrument - Blocks', error)
        }
    }


    return (
        <>
            {blocks !== undefined && blocks.length > 0 && (
                blocks.map((block, index) => (
                    <div key={index} ref={(el) => (blockRefs.current[index] = el)} className={`Blocks ${activeBlocks[index] ? 'Blocks-Show' : ''}`} onDoubleClick={() => ToggleList(index)}>
                        <div className="Block">
                            <div className="Block-Info">
                                <p className="Block-Title">{block.name}</p>
                                <ProgressBar percentageCompleted={block.percentageCompleted} />
                            </div>

                            {/* Botón para mostrar/ocultar bloque */}
                            <div className="Block-Buttons">
                                <button type="button" className="Block-Buttons-List" onClick={() => ToggleList(index)}>
                                    <i className={`bi bi-caret-${activeBlocks[index] ? 'up' : 'down'}-fill`}></i>
                                </button>
                            </div>
                        </div>

                        <ul className={`Topics ${activeBlocks[index] ? 'Topics-Show' : ''}`}>
                            {/* Botón para mostrar/ocultar el objetivo */}
                            <button type="button" className="Topics-Buttons-Objective" onClick={() => ToggleShowObjective(index)} >
                                {showObjectives[index] ? translate('labels.course.blocks.hideTopicObjective') : translate('labels.course.blocks.showTopicObjective')}
                            </button>

                            {/* Cuadro del contenido del objetivo */}
                            <div className={`Topics-Objective ${ showObjectives[index] ? 'Topics-Objective-Show' : '' }`}>
                                <button className="Topics-Objective-Close" onClick={() => ToggleShowObjective(index)} ><i className="bi bi-x-lg"></i></button>
                                
                                <p>{translate('labels.course.blocks.topicObjective')}</p>
                                <p>{block.objective}</p>
                            </div>

                            {/* Listado de temas */}
                            {block.items.map((item, subIndex) => (
                                <li key={subIndex} className={`Topics-${item.type === 1 ? 'Exam' : item.type === 2 ? 'Item' : 'Activity'}`} onClick={() => GoToInstrument(item)} ref={(el) => (topicRefs.current[item.id] = el)}>{item.name}</li>
                            ))}
                        </ul>
                    </div>
                ))
            )}
        </>
    )
}

export { Blocks };








//Falta completar esto
//Situaciones por abarcar:
//Si es la primera vez, debo poner activo el primer Examen y abrir el listado de este. ✅
//Si viene de otra pantalla y ya tiene current, lo debo devolver a la competencia donde se quedó (Abrir Examen y posicionar en Tema). 
//Si viene de otra pantalla y NO tiene current, debo poner activo el primer o siguiente Examen NO terminado y abrir el listado de este. 



// {
//     "objective": "Texto del objetivo general",
//     "blocks": [
//         {
//             "id": 1,
//             "name": "Nombre de bloque",
//             "objective": "Texto del objetivo",
//             "items": [
//                 {
//                     "id": 1,
//                     "name": "Nombre de tema",
//                     "type": 1
//                 }
//             ]
//         },
//         {
//             "id": 2,
//             "name": "Nombre de bloque",
//             "objective": "Texto del objetivo",
//             "items": [
//                 {
//                     "id": 2,
//                     "name": "Nombre de tema",
//                     "type": 2
//                 },
//                 {
//                     "id": 3,
//                     "name": "Nombre de tema",
//                     "type": 2
//                 },
//                 {
//                     "id": 4,
//                     "name": "Nombre de tema",
//                     "type": 2
//                 },
//                 {
//                     "id": 5,
//                     "name": "Nombre de tema",
//                     "type": 2
//                 }
//             ]
//         },
//         {
//             "id": 3,
//             "name": "Nombre de bloque",
//             "objective": "Texto del objetivo",
//             "items": [
//                 {
//                     "id": 6,
//                     "name": "Nombre de tema",
//                     "type": 3
//                 },
//                 {
//                     "id": 7,
//                     "name": "Nombre de tema",
//                     "type": 1
//                 }
//             ]
//         }
//     ]
// }