import { useEffect, useState } from "react"

import { LogErrors } from './../../../../utilities/LogErrors'
import { useLanguage } from './../../../../utilities/LanguageContext'

//Funciones personalizadas para el proyecto
import { GetRandomizedOptions } from './../../../../utilities/BL/Student/Instruments'

//Componente comunes generales
import { Loading } from './../../../Common/Loading'


//Agregar mensajes de errores


const Challenges = ({ instrument }) => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const [isLoading, setIsLoading] = useState(true)

    const [quizData, setQuizData] = useState(null)
    const [isCorrect, setIsCorrect] = useState(null) // null (sin respuesta), true (correcto), false (incorrecto)
    const [readyToEvaluate, setReadyToEvaluate] = useState(false)
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(null) // Nuevo estado para la opción seleccionada


    const Validate = async (isCorrect, index) => {
        try {
            setReadyToEvaluate(true)
            setIsCorrect(isCorrect)
            setSelectedOptionIndex(index) // Actualizar la opción seleccionada
        } catch (error) {
            await LogErrors('Validate - Challenges', error)
        }
    }

    const GetOptions = async () => {
        try {
            const response = await GetRandomizedOptions(instrument.challenge.options)

            if (response.success) {
                const tmpQuizData = {
                    question: instrument.challenge.question,
                    options: response.data
                }

                setQuizData(tmpQuizData)
            } else {
                //Mostrar mensaje de que no se puedo obtener la info o el error
                console.log(response.message) //Mensaje temporal
            }
        } catch (error) {
            await LogErrors('GetOptions - Challenges', error)
        }
    }

    const ResetChallenge = async () => {
        try {
            setReadyToEvaluate(false)
            setIsCorrect(null)
            setSelectedOptionIndex(null)

            await GetOptions()
        } catch (error) {
            await LogErrors('ResetChallenge - Challenges', error)
        }
    }

    const LoadPage = async () => {
        try {
            await GetOptions()
        } catch (error) {
            await LogErrors('LoadPage - Challenges', error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        LoadPage()
    }, [])


    return (
        <div className={`container Challenges Challenges-${readyToEvaluate ? isCorrect === true ? 'Good' : 'Bad' : ''}`}>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    {/* <p className="Challenges-Text">{isCorrect === null ? quizData?.question : isCorrect ? translate('labels.viewer.challenges.correctMessage') : translate('labels.viewer.challenges.incorrectMessage')}</p> */}

                    {isCorrect === null ? (
                        <p className="Challenges-Text" dangerouslySetInnerHTML={{ __html: quizData?.question }} />
                    ) : (
                        isCorrect ? (
                             <p className="Challenges-Text">{translate('labels.viewer.challenges.correctMessage')}</p>
                        ) : (
                            <p className="Challenges-Text">{translate('labels.viewer.challenges.incorrectMessage')}</p>
                        )
                    )}
                    
                    {quizData && (
                        <>
                            <div className="Challenges-Options">
                                {quizData.options.map((option, index) => (
                                    <img
                                        key={index}
                                        src={option.file}
                                        alt={`Opción #${index + 1}`}
                                        className={`Challenges-Option
                                            ${selectedOptionIndex !== null && selectedOptionIndex !== index ? 'Challenges-Option-Unselected' : ''}
                                            ${readyToEvaluate && selectedOptionIndex === index && option.isCorrect ? 'Challenges-Option-Good' : ''}
                                            ${readyToEvaluate && selectedOptionIndex === index && !option.isCorrect ? 'Challenges-Option-Bad' : ''}`}
                                        onClick={() => Validate(option.isCorrect, index)} />
                                ))}
                            </div>

                            {readyToEvaluate && <button type="button" className="Challenges-Button-Reset" onClick={ResetChallenge}><i className="bi bi-arrow-counterclockwise"></i> Reintentar</button>}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export { Challenges };