import { useLanguage } from './../../../../utilities/LanguageContext'


const HorizontalVideo = ({ instrument }) => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto

    
    return (
        <video className='Video-Video' controls>
            <source src={instrument.video.file} type='video/mp4' />
            {translate('labels.viewer.videos.tagLegend')}

            <track src={instrument.video.subtitles} kind="subtitles" srclang="es" label="Español" default />
        </video>
    )
}

export { HorizontalVideo };