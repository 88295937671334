import { LogErrors } from './../LogErrors'


//Agregar multiidioma en los mensajes


/**
 * Función para detectar inyección SQL (ejemplo básico)
 * @param {string} value 
 * @returns 
 */
const ValidateSqlInjection = async (value) => {
    try {    
        const sqlInjectionKeywords = ['SELECT', 'INSERT', 'UPDATE', 'DELETE', 'DROP'] //';', '--'
        
        return sqlInjectionKeywords.some(keyword => value.toUpperCase().includes(keyword))
    } catch (error) {
        await LogErrors('ValidateSqlInjection BL Security', error)
    }
}

/**
 * Valida la seguridad de una contraseña según múltiples criterios.
 * 
 * @param {string} password - La contraseña a validar.
 * @returns {string|null} - Mensaje de error si hay una falla, o `null` si es válida.
 */
const ValidatePassword = async (password) => {
    try {
        if (!password) {
            return 'La contraseña es obligatoria'
        }
    
        if (password.length < 8) {
            return 'La contraseña debe tener al menos 8 caracteres'
        }
    
        if (!/[A-Z]/.test(password)) {
            return 'La contraseña debe contener al menos una letra mayúscula'
        }
    
        if (!/[a-z]/.test(password)) {
            return 'La contraseña debe contener al menos una letra minúscula'
        }
    
        if (!/[0-9]/.test(password)) {
            return 'La contraseña debe contener al menos un número'
        }
    
        if (!/[!@#$%^&*()\-=+[\]{}|:'",.<>?/`~]/.test(password)) {
            return 'La contraseña debe contener al menos un carácter especial (!@#$%^&*()-_=+[]{}|:\'",.<>?/`~)'
        }
    
        if (await ValidateSqlInjection(password)) {
            return 'La contraseña contiene patrones inválidos'
        }
    
        return null // Indica que la contraseña es válida
    } catch (error) {
        await LogErrors('ValidatePassword BL Security', error)
    }
}

export { ValidateSqlInjection, ValidatePassword };