import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { LogErrors } from './../../utilities/LogErrors'
import { useLanguage } from './../../utilities/LanguageContext'

import { ValidateSession } from './../../utilities/BL/Sessions'

//Componentes personalizados del proyecto
import { Blocks } from './Blocks'

//Componentes comunes generales
import { Header } from './../Header/Header'
import { Loading } from './../Common/Loading'
import { SearchInput } from './SearchInput'

//Funciones personalizadas del proyecto
import { GetChoosenCourse } from './../../utilities/BL/Student/Courses' //ResetChoosenCourse
//import { ResetInstrumentToShow } from './../../utilities/BL/Navigation'
import { GetAndSaveInstrumentToShow, GetInstrumentName } from './../../utilities/BL/Student/Instruments'
import { GoToBack } from './../Common/GoToBack'
import { GoToTop } from './../Common/GoToTop'


const Course = () => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)
    const [session, setSession] = useState({})
    const [theme, setTheme] = useState({})

    const [course, setCourse] = useState({})
    const [courseInstruments, setCourseInstruments] = useState({})
    const [quickaccessName, setQuickaccessName] = useState(null)
    const [isObjectiveVisible, setIsObjectiveVisible] = useState(false)


    const GoToNextView = async () => {
        try {
            const response = await GetAndSaveInstrumentToShow(session)

            if (response.success) {
                navigate('/viewer')
            } else {
                //Mostrar mensaje de que no se puedo obtener la info o el error
                console.log(response.message) //Mensaje temporal
            }
        } catch (error) {
            await LogErrors('GoToNextView - Course', error)
        }
    }

    const LoadPage = async (sessionInfo) => {
        try {
            setTheme(sessionInfo.user.userTheme)


            const response = await GetChoosenCourse(sessionInfo)
            
            if (response.success) {
                if (sessionInfo.navigation.course.goToQuickAccessInstrument) {
                    const tmpResponse = await GetAndSaveInstrumentToShow(sessionInfo)

                    if (tmpResponse.success) {
                        navigate('/viewer')
                    } else {
                        //Mostrar mensaje de que no se puedo obtener la info o el error
                        console.log(tmpResponse.message) //Mensaje temporal
                    }
                } else {
                    setCourse(sessionInfo.navigation.course)
                    setCourseInstruments(response.data)
                    
                    const tmpQuickaccessName = await GetInstrumentName(sessionInfo)

                    if (tmpQuickaccessName.success) {
                        setQuickaccessName(tmpQuickaccessName.data)
                    } else {
                        //Mostrar mensaje de que no se puedo obtener la info o el error
                        console.log(tmpQuickaccessName.message) //Mensaje temporal
                    }

                    // Abrir el bloque donde se encuentra el tema que se había seleccionado antes de enviar a la página Viewer
                    if (sessionInfo.navigation.instrument !== undefined && sessionInfo.navigation.instrument.id) {
                        const foundBlockIndex = response.data.blocks.findIndex(block =>
                            block.items.some(item => item.id === parseInt(sessionInfo.navigation.instrument.id))
                        )

                        if (foundBlockIndex !== -1) {
                            // Pasamos el bloque a abrir al estado de Blocks.js
                            setTimeout(() => {
                                document.getElementById(`block-${foundBlockIndex}`)?.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'start',
                                })
                            }, 500)
                        }
                    }
                    
                    //await ResetInstrumentToShow(sessionInfo)
                }
            } else {
                //Mostrar mensaje de que no se puedo obtener la info o el error
                console.log(response.message) //Mensaje temporal
            }
        } catch (error) {
            await LogErrors('LoadPage - Course', error)    
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        async function Validate() {
            const tmpSession = await ValidateSession()
            setSession(tmpSession.data)
    
            if (tmpSession.success) {
                if (tmpSession.data.user.isConfirmed) {
                    await LoadPage(tmpSession.data)
                } else {
                    navigate('/confirm')
                }
            } else {
                navigate('/')
            }
        }
        Validate()
    },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar

    const ToggleCourseObjective = async () => {
        try {
            setIsObjectiveVisible(!isObjectiveVisible)
        } catch (error) {
            await LogErrors('ToggleCourseObjective - Course', error)
        }
    }


    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Header theme={theme} />

                    <main className='container Course'>
                        <GoToBack url={'/dashboard'} />

                        {course && courseInstruments && (
                            <>
                                <div className='Course-Info'>
                                    <div className='Course-Text'>
                                        <p className='Course-Name'>{`${course.name} nivel ${course.level === 1 ? translate('labels.course.levels.one') : translate('labels.course.levels.two')}`}</p>
                                        <p className='Course-Type'>{course.type === 1 ? translate('labels.dashboard.tiles.examType.certification') : translate('labels.dashboard.tiles.examType.custom')}</p>
                                        {courseInstruments.objective && courseInstruments.objective !== '' && (
                                            <>
                                                <button type='button' className='Course-Objective-Button' onClick={ToggleCourseObjective}>{isObjectiveVisible ? 'Ocultar objetivo del curso' : 'Ver objetivo del curso'}</button>
                                                
                                                <div className={`Course-Objective ${isObjectiveVisible ? 'Course-Objective-Show' : ''}`}>
                                                    <p className='Course-Objective-Title'>{translate('labels.course.courseObjective')}</p>
                                                    <p className='Course-Objective-Text'>{courseInstruments.objective}</p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>

                                {quickaccessName && (
                                    <>
                                        <p className='Course-Title'>{translate('labels.course.continueLegend')}</p>
                                        
                                        <button type='button' className='Course-Button' onClick={() => GoToNextView()}>
                                            <span className='Course-Button-Text'>{quickaccessName}</span>
                                        </button>
                                    </>
                                )}

                                <p className='Course-Title'>{translate('labels.course.topicsTitle')}</p>
                                <SearchInput placeholder={`${translate('labels.course.searchInput')}`} session={session} courseInstruments={courseInstruments} />

                                <div className='Course-Topics'>
                                    <Blocks blocks={courseInstruments.blocks} session={session} setSession={setSession} quickaccessName={quickaccessName} />
                                </div>
                            </>
                        )}
                    </main>

                    <GoToTop />
                </>
            )}
        </>
    )  
}

export { Course };
















// const Course = () => {
//     const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
//     const navigate = useNavigate()

//     const [isLoading, setIsLoading] = useState(true)
//     const [session, setSession] = useState({})

//     const [course, setCourse] = useState({})
//     const [courseInstruments, setCourseInstruments] = useState({})
//     const [quickaccessName, setQuickaccessName] = useState(null)
//     const [isObjectiveVisible, setIsObjectiveVisible] = useState(false)


//     const GoToNextView = async () => {
//         try {
//             const response = await GetAndSaveInstrumentToShow(session)

//             if (response.success) {
//                 navigate('/viewer')
//             } else {
//                 //Mostrar mensaje de que no se puedo obtener la info o el error
//                 console.log(response.message) //Mensaje temporal
//             }
//         } catch (error) {
//             await LogErrors('GoToNextView - Course', error)
//         }
//     }

//     const LoadPage = async (sessionInfo) => {
//         try {
//             const response = await GetChoosenCourse(sessionInfo)
            
//             if (response.success) {
//                 if (response.data.navigation.course.goToQuickAccessInstrument) {
//                     const tmpResponse = await GetAndSaveInstrumentToShow(response.data)

//                     if (tmpResponse.success) {
//                         navigate('/viewer')
//                     } else {
//                         //Mostrar mensaje de que no se puedo obtener la info o el error
//                         console.log(tmpResponse.message) //Mensaje temporal
//                     }
//                 } else {
//                     // const item = data.items.find((item) => item.id === id)
//                     setCourse(response.data.navigation.course)
//                     setCourseInstruments(response.data)
                    
//                     const tmpQuickaccessName = await GetInstrumentName(response.data)

//                     if (tmpQuickaccessName.success) {
//                         setQuickaccessName(tmpQuickaccessName.data)
//                     } else {
//                         //Mostrar mensaje de que no se puedo obtener la info o el error
//                         console.log(tmpQuickaccessName.message) //Mensaje temporal
//                     }
//                 }
//             } else {
//                 //Mostrar mensaje de que no se puedo obtener la info o el error
//                 console.log(response.message) //Mensaje temporal
//             }
//         } catch (error) {
//             await LogErrors('LoadPage - Course', error)
//         } finally {
//             setIsLoading(false)
//         }
//     }

//     useEffect(() => {
//         async function Validate() {
//             const tmpSession = await ValidateSession()
//             setSession(tmpSession.data)
    
//             if (tmpSession.success) {
//                 if (tmpSession.data.user.isConfirmed) {
//                     await LoadPage(tmpSession.data)
//                 } else {
//                     navigate('/confirm')
//                 }
//             } else {
//                 navigate('/')
//             }
//         }
//         Validate()
//     },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar

//     const ToggleCourseObjective = async () => {
//         try {
//             setIsObjectiveVisible(!isObjectiveVisible)
//         } catch (error) {
//             await LogErrors('ToggleCourseObjective - Course', error)
//         }
//     }


//     return (
//         <>
//             {isLoading ? (
//                 <Loading />
//             ) : (
//                 <>
//                     <Header theme={session.user.theme} />

//                     <main className='container Course'>
//                         {course && courseInstruments && (
//                             <>
//                                 <div className='Course-Info'>
//                                     <div className='Course-Text'>
//                                         <p className='Course-Name'>{`${course.name} nivel ${course.level === 1 ? translate('labels.course.levels.one') : translate('labels.course.levels.two')}`}</p>
//                                         <p className='Course-Type'>{course.type === 1 ? translate('labels.dashboard.tiles.examType.certification') : translate('labels.dashboard.tiles.examType.custom')}</p>
//                                         {courseInstruments.objective && courseInstruments.objective !== '' && (
//                                             <>
//                                                 <button type='button' className='Course-Objective-Button' onClick={ToggleCourseObjective}>{isObjectiveVisible ? 'Ocultar objetivo del curso' : 'Ver objetivo del curso'}</button>
                                                
//                                                 <div className={`Course-Objective ${isObjectiveVisible ? 'Course-Objective-Show' : ''}`}>
//                                                     <p className='Course-Objective-Title'>{translate('labels.course.courseObjective')}</p>
//                                                     <p className='Course-Objective-Text'>{courseInstruments.objective}</p>
//                                                 </div>
//                                             </>
//                                         )}
//                                     </div>
//                                 </div>

//                                 {quickaccessName && (
//                                     <>
//                                         <p className='Course-Title'>{translate('labels.course.continueLegend')}</p>
                                        
//                                         <button type='button' className='Course-Button' onClick={() => GoToNextView()}>
//                                             <span className='Course-Button-Text'>{quickaccessName}</span>
//                                         </button>
//                                     </>
//                                 )}

//                                 <p className='Course-Title'>{translate('labels.course.topicsTitle')}</p>
//                                 <SearchInput placeholder={`${translate('labels.course.searchInput')}`} session={session} courseInstruments={courseInstruments} />

//                                 <div className='Course-Topics'>
//                                     <Blocks blocks={courseInstruments.blocks} session={session} quickaccessName={quickaccessName} />
//                                 </div>
//                             </>
//                         )}
//                     </main>
//                 </>
//             )}
//         </>
//     )  
// }

// export { Course };