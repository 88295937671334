import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LogErrors } from './../../utilities/LogErrors'
import { useLanguage } from './../../utilities/LanguageContext' // Importa el hook

//Funciones comunes
import { Login as BLLogin } from './../../utilities/BL/Logins'
import { ValidateSession, DeleteSessionInfo } from './../../utilities/BL/Sessions'

//Componentes comunes
import { Input } from './Fields/Input'
import { Checkbox } from './Fields/Checkbox'
import { Button } from './../Common/Button'
import { Loading } from './../Common/Loading'



const Login = () => {
  const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
  const navigate = useNavigate()

  //Estados
  const [formData, setFormData] = useState({user: '', password: '', remember: false}) // Estado que maneja la info del formulario
  const [errors, setErrors] = useState({}) // Estado que maneja los errores
  const [isSubmitted, setIsSubmitted] = useState(false) // Estado que controla si se ha enviado, o no, el formulario
  const [isLoading, setIsLoading] = useState(true)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  
  // Objetos que almacenan los controles del formulario
  const userRef = useRef(null)
  const passwordRef = useRef(null)


  //--- Funciones personalizadas ---//
  const LoadPage = async () => {
    try {
      const session = await ValidateSession()

      if (session.success) {
        if (session.data.user.isConfirmed) {
          navigate('/dashboard') // Navega al dashboard si el login fue exitoso
        } else {
          navigate('/confirm')
        }
      } else {
        DeleteSessionInfo()
        setIsLoading(false)
      }
    } catch (error) {
      await LogErrors('LoadPage - Login', error)
    }
  }

  const Login = async (e) => {
    try {
      e.preventDefault()
      setIsButtonLoading(true)

      const session = ValidateSession()

      if (session.success) {
        if (session.data.isConfirmed) {
          navigate('/dashboard') // Navega al dashboard si el login fue exitoso
        } else {
          navigate('/confirm')
        }
      } else {
        setIsSubmitted(true) // Marcar el formulario como enviado

        const response = await BLLogin(formData)

        if (response.success) {
          if (response.data.user.isConfirmed) {
            navigate('/dashboard') // Navega al dashboard si el login fue exitoso
          } else {
            navigate('/confirm')
          }
        } else {
          setErrors(response.errors) // Muestra los errores si los hay

          // Hacer focus en el campo correspondiente
          if (response.errors.user) {
            userRef.current.focus()
          } else if (response.errors.password) {
            passwordRef.current.focus()
          }
        }
      }
    } catch (error) {
      await LogErrors('Login - Login', error)
    } finally {
      setIsButtonLoading(false)
    }
  }
  //--- Funciones personalizadas ---//



  //--- Eventos de la página web ---//
  useEffect(() => {
    //Establecer el tema claro a la web
    document.body.classList.remove('DarkTheme')
    
    LoadPage()
  },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar
  //--- Eventos de la página web ---//



  //--- Eventos de formulario ---//
  /**
   * Función que se ejecuta cuando se realiza un cambio en el checkbox del formulario.
   * Obtiene la info de los campos del formulario y actualiza el valor del checkbox (True o False)
   * @param {Event} e 
   */
  const InputChange = async (e) => {
    try {  
      const { id, value, type, checked } = e.target
      
      setFormData({
        ...formData,
        [id]: type === 'checkbox' ? checked : value
      })
    } catch (error) {
      await LogErrors('InputChange - Login', error)
    }
  }

  /**
   * Función que se ejecuta cuando se presiona la tecla Enter o se presiona el botón del formulario
   * @param {Event} e 
   */
  const FormSubmit = async (e) => {
    try {
      Login(e)
    } catch (error) {
      await LogErrors('FormSubmit - Login', error)
    }
  }

  /**
   * Función que se ejecuta al presiona la tecla Enter en el formulario
   * @param {Event} e 
   */
  const EnterKeyPressInForm = async (e) => {
    try {
      if (e.key === 'Enter') {
        Login(e)
      }
    } catch (error) {
      await LogErrors('EnterKeyPressInForm - Login', error)
    }
  }
  //--- Eventos de formulario ---//



  return (
    <>
      <main className='container Container-FullHeight Container-FlexContent'>
        {isLoading ? (
          <Loading />
        ) : (
          <div className='Login'>
            <img src="./img/brand/Ztudia-Logotipo-Negro-350x135.png" className='Login-Image'/>

            <form className="loginForm" onSubmit={FormSubmit} onKeyPress={EnterKeyPressInForm}>
              <p id='formTitle' className='loginForm-Title'>{translate('labels.login.title')}</p>

                <Input
                  type={'text'}
                  id={'user'}
                  value={formData.user}
                  onChange={InputChange}
                  placeholder={translate('labels.login.username')}
                  error={isSubmitted ? errors.user : null} // Mostrar error solo si se ha enviado el formulario
                  isSubmitted={isSubmitted} // Pasar isSubmitted al Input
                  ref={userRef}
                />

                <Input
                  type={'password'} 
                  id={'password'}
                  value={formData.password}
                  onChange={InputChange}
                  placeholder={translate('labels.login.password')}
                  error={isSubmitted ? errors.password : null} // Mostrar error solo si se ha enviado el formulario
                  isSubmitted={isSubmitted} // Pasar isSubmitted al Input
                  ref={passwordRef}
                />

                <Checkbox id='remember' checked={formData.remember} onChange={InputChange} label={translate('labels.login.remember')} />

                {isButtonLoading ? (
                  <button type='button' className='loginForm-Button loginForm-Button-Disabled'>{translate('labels.login.loadingSubmit')}</button>
                ) : (
                  <button type='submit' className='loginForm-Button'>{translate('labels.login.submit')}</button>
                )}
                {errors.general && <p className='loginForm-Error-Message'>{errors.general}</p>}

            </form>

            <div className='Login__Download'>
              <a className='Login__Download__Link' onClick={() => navigate('/download')}><i className="bi bi-download"></i> Descargar solución Ztudia</a>
            </div>
          </div>
        )}
      </main>

      {/* <footer className='Login-Footer'>
        <p>{translate('labels.credits.footer')}</p>
      </footer> */}
    </>
  )
}

export { Login };