import { DLGetSettings } from './../DL/Settings'

import { LogErrors } from './../LogErrors'
import { UpdateSessionInfo } from './Sessions'

import { SaveWebTheme as DLSaveWebTheme, SaveWebFontSize as DLSaveWebFontSize } from './../DL/Settings'


const SetDefaultWebTheme = async () => {
  try {
    //Validar que exista la info del usuario en el session storage
    if (!sessionStorage.getItem('userData')) {
      return { success: false, message: '' } //En este caso borraron los datos; así que le muestro mensaje indicando que su sesión ha expirado.
    }
    
    //Obtener la info del usuario desde el session storage
    const userData = JSON.parse(sessionStorage.getItem('userData'))

    //Comprobar varias propiedades para ver si está correcta la info del usuario
    if (userData.user.userTheme === undefined || userData.user.userTheme === null) {
      document.body.classList.remove('DarkTheme')
      // return { success: false, message: '' } //Mensaje indicando que no existe la llave en donde se colocará el idioma
      return { success: false, data: 1, message: '' } //Mensaje indicando que no existe la llave en donde se colocará el idioma
    }

    //Establecer el tema de acuerdo a la info que se obtuvo
    if (userData.user.userTheme === 1) {
      document.body.classList.remove('DarkTheme')
    } else if (userData.user.userTheme === 2) {
      document.body.classList.add('DarkTheme')
    } else {
      document.body.classList.remove('DarkTheme')
    }

    return { success: true, data: userData.user.userTheme }
  } catch (error) {
    await LogErrors('SetDefaultWebTheme - Settings BL', error)
    return { success: false, data: 1, message: '' }
  }
}

const SaveWebTheme = async (token, themeToSave) => {
  try {
    let theme = null
    
    switch (themeToSave.toLowerCase()) {
      case 'light':
        theme = 1
        break
      case 'dark':
        theme = 2
        break
    }

    if (theme === null) {
      return { success: false, message: '' } //Mensaje de que ese tipo de tema no existe
    }

    //Validar que exista la info del usuario en el session storage
    if (!sessionStorage.getItem('userData')) {
        return { success: false, message: '' } //En este caso borraron los datos; así que le muestro mensaje indicando que su sesión ha expirado.
    }
    
    //Obtener la info del usuario desde el session storage
    const userData = JSON.parse(sessionStorage.getItem('userData'))

    //Comprobar varias propiedades para ver si está correcta la info del usuario
    if (userData.user === undefined) {
      return { success: false, message: '' } //Mensaje indicando que no existe la llave en donde se colocará el idioma
    }

    userData.user.userTheme = theme
    
    //Agregar llamado a la API para guardar el tema
    const response = await DLSaveWebTheme(token, theme)

    if (response.success) {
      await UpdateSessionInfo(userData)

      if (theme === 1) {
        document.body.classList.remove('DarkTheme')
      } else if (theme === 2) {
        document.body.classList.add('DarkTheme')
      }
      
      return { success: true, data: themeToSave }
    } else {
      return { success: false, message: response.message }
    }
  } catch (error) {
    await LogErrors('SaveWebTheme - Settings BL', error)
    return { success: false, message: '' }
  }
}

const SaveWebFontSize = async (token, fontSizeToSave) => {
  try {
    let fontSize = null
    
    switch (fontSizeToSave.toLowerCase()) {
      case 'smallfont':
        fontSize = 2
        break
      case 'mediumfont':
        fontSize = 1
        break
      case 'bigfont':
        fontSize = 3
        break
    }

    if (fontSize === null) {
      return { success: false, message: '' } //Mensaje de que ese tipo de tema no existe
    }

    //Validar que exista la info del usuario en el session storage
    if (!sessionStorage.getItem('userData')) {
        return { success: false, message: '' } //En este caso borraron los datos; así que le muestro mensaje indicando que su sesión ha expirado.
    }
    
    //Obtener la info del usuario desde el session storage
    const userData = JSON.parse(sessionStorage.getItem('userData'))

    //Comprobar varias propiedades para ver si está correcta la info del usuario
    if (userData.user === undefined) {
      return { success: false, message: '' } //Mensaje indicando que no existe la llave en donde se colocará el idioma
    }

    //userData.user.fontSize = fontSize
    userData.user.fontZise = fontSize
    
    const response = await DLSaveWebFontSize(token, fontSize)

    if (response.success) {
      await UpdateSessionInfo(userData)

      if (fontSize === 2) {
        document.body.classList.remove('BigFont')
        document.body.classList.add('SmallFont')
      } else if (fontSize === 1) {
        document.body.classList.remove('SmallFont')
        document.body.classList.remove('BigFont')
      } else if (fontSize === 3) {
        document.body.classList.remove('SmallFont')
        document.body.classList.add('BigFont')
      }

      return { success: true, data: null }
    } else {
      return { success: false, message: response.message }
    }
  } catch (error) {
    await LogErrors('SaveWebFontSize - Settings BL', error)
    return { success: false, message: '' }
  }
}














































/**
 * Función para obtener las configuraciones de la plataforma
 * @param {string} language 
 * @param {boolean} isLoginPage 
 * @returns 
 */
const GetSettings = async (language = null, isLoginPage = false) => {
    try {
      const settings = await DLGetSettings(language, isLoginPage) // Esperar a que getSettings termine

      if (settings === null) {
        return null
      }
      
      return settings
    } catch (error) {
      console.log(error)
    }
}





const GetMenu = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine

    if (settings === null || (settings.menu === undefined || settings.menu === null)) {
      return null
    }

    return settings.menu
  } catch (error) {
    console.log(error)
  }
}





const GetMessages = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine
    
    if (settings === null || (settings.messages === undefined || settings.messages === null)) {
      return null
    }

    return settings.messages
  } catch (error) {
    console.log(error)
  }
}





/**
 * Función que obtiene el tema de las configuraciones del usuario
 * @returns array
 */
const GetTheme = () => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine

    if (settings === null || (settings.theme === undefined || settings.theme === null)) {
      return null
    }

    return settings.theme
  } catch (error) {
    console.log(error)
  }
}

/**
 * Función que establece el nuevo tema en las configuraciones del usuario
 * @param {string} $theme 
 * @returns boolean
 */
const SetTheme = ($theme) => {
  try {
    const settings = DLGetSettings() // Esperar a que getSettings termine

    if (settings === null || (settings.theme === undefined || settings.theme === null)) {
      return null
    }

    if ($theme === null || $theme === '' || ($theme.toLowerCase() !== 'dark' && $theme.toLowerCase() !== 'light')) {
      return false
    }

    settings.theme = $theme.toLowerCase()
    sessionStorage.setItem('settings',JSON.stringify(settings))
    return true
  } catch (error) {
    console.log(error)
  }
}

// const GetLanguage = () => {
//   try {
//     const settings = DLGetSettings() // Esperar a que getSettings termine
    
//     if (settings === null || (settings.messages === undefined || settings.messages === null)) {
//       return null
//     }

//     return settings.messages
//   } catch (error) {
//     console.log(error)
//   }
// }

export { SetDefaultWebTheme, SaveWebTheme, SaveWebFontSize, GetSettings, GetMenu, GetMessages, GetTheme, SetTheme };