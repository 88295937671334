import { LogErrors } from './../../LogErrors'


const GetLearningInstruments = async (token, navigationSession) => {
    try {
        const dataToSend = {
            "courseId": navigationSession.course.id,
            "itemId": navigationSession.instrument.id
        }
        
        const response = await fetch("https://ztudia.xyz/api/Api/GetLearningInstruments", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(dataToSend)
        })
        
        if (!response.ok) {
            // Manejar errores HTTP
            const errorData = await response.json()
            //return { success: false, message: errorData.message || 'Error de autenticación' }
            return { success: false, message: errorData.message } //errorCode
        }
    
        const data = await response.json()
    
        // Retornar la respuesta exitosa
        return { success: true, data: data }
    } catch (error) {
        await LogErrors('GetLearningInstruments - DL Instruments', error)
        return { success: false, message: '' }
    }
}

const GetActivity = async (token, navigationSession) => {
    try {
        const dataToSend = {
            "courseId": navigationSession.course.id,
            "itemId": navigationSession.instrument.id
        }
        
        const response = await fetch("https://ztudia.xyz/api/Api/GetActivity", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(dataToSend)
        })
        
        if (!response.ok) {
            // Manejar errores HTTP
            const errorData = await response.json()
            //return { success: false, message: errorData.message || 'Error de autenticación' }
            return { success: false, message: errorData.message } //errorCode
        }
    
        const data = await response.json()
    
        // Retornar la respuesta exitosa
        return { success: true, data: data }
    } catch (error) {
        await LogErrors('GetActivity - DL Instruments', error)
        return { success: false, message: '' }
    }
}

const GetExam = async (token, navigationSession) => {
    try {
        const dataToSend = {
            "idCenter": navigationSession.center.id,
            "idCourse": navigationSession.course.id,
            "idItem": navigationSession.instrument.id
        }

        const response = await fetch("https://ztudia.xyz/api/Api/GenerateTokenEvaluation", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(dataToSend)
        })
        
        if (!response.ok) {
            // Manejar errores HTTP
            const errorData = await response.json()
            //return { success: false, message: errorData.message || 'Error de autenticación' }
            return { success: false, message: errorData.message } //errorCode
        }
        
        const data = await response.json()
    
        // Retornar la respuesta exitosa
        return { success: true, data: data }
    } catch (error) {
        await LogErrors('GetExam - DL Instruments', error)
        return { success: false, message: '' }
    }
}

export { GetLearningInstruments, GetActivity, GetExam };