import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { LogErrors } from './../../utilities/LogErrors'
import { useLanguage } from './../../utilities/LanguageContext'

//Funciones personalizadas
import { SaveWebTheme, SaveWebFontSize } from './../../utilities/BL/Settings'
import { Logout as BLLogout, GetSessionToken } from './../../utilities/BL/Sessions'


const InstrumentsHeader = ({ instrumentData, theme }) => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()
    
    const [theTheme, setTheTheme] = useState({})
    

    const ChangeTheme = async (themeToSave) => {
        try {
            const sessionToken = await GetSessionToken()

            if (sessionToken.success) {
                const response = await SaveWebTheme(sessionToken.data, themeToSave)
                
                if (response.success) {
                    setTheTheme(response.data)
                }
            }
        } catch (error) {
            await LogErrors('ChangeTheme - InstrumentsHeader', error)
        }
    }

    const ChangeFontSize = async (fontSizeToSave) => {
        try {
            const sessionToken = await GetSessionToken()

            if (sessionToken.success) {
                await SaveWebFontSize(sessionToken.data, fontSizeToSave)
            }
        } catch (error) {
            await LogErrors('ChangeFontSize - InstrumentsHeader', error)
        }
    }

    const Logout = async () => {
        try {
            const sessionToken = await GetSessionToken()

            if (sessionToken.success) {
                const response = await BLLogout(sessionToken.data)

                if (response.success) {
                    navigate("/")
                } else {
                    //Mostrar mensaje de que no se pudo cerrar la sesión correctamente. Intenta nuevamente o más tarde.
                    //En un componente de mensajes
                }
            }
        } catch (error) {
            await LogErrors('Logout - InstrumentsHeader', error)
        }
    }

    const LoadPage = async () => {
        try {
            if (theme === 1) {
                setTheTheme('light')
            } else if (theme === 2) {
                setTheTheme('dark')
            } else {
                setTheTheme('light')
            }
        } catch (error) {
            await LogErrors('LoadPage - InstrumentsHeader', error)
        }
    }

    /**
     * Función para obtener la configuración, una vez que termine de cargarse el componente
     */
    useEffect(() => {
        LoadPage()
    }, []) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar


    return (
        <header className="container Header">
            <div className="Header-Side1">
                <div className="Header-Brand">
                    {theTheme === 'dark' ? (
                        <img src="./img/brand/Ztudia-Logotipo-Blanco-66x26.png" className="Header-Image" />
                    ) : (
                        <img src="./img/brand/Ztudia-Logotipo-Negro-66x26.png" className="Header-Image" />
                    )}
                </div>
            </div>

            {instrumentData && (
                <div className="Header-Side2">
                    {instrumentData.block !== undefined && instrumentData.block !== '' && <p className="Header-Text">Bloque: {instrumentData.block}</p>}
                    {instrumentData.topic !== undefined && instrumentData.topic !== '' && <p className="Header-Text">{instrumentData.topic}</p>}
                    {(instrumentData.steps.start !== undefined && instrumentData.steps.end !== undefined) && (instrumentData.steps.start !== null && instrumentData.steps.end !== null) && <p className="Header-Text">Tema {instrumentData.steps.start} de {instrumentData.steps.end}</p>}
                </div>
            )}

            <div className="Header-Side3">
                <div className="Header-Menu btn-group">
                    <button type="button" className="dropdown-toggle Header-Menu-Button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-person-circle"></i>
                    </button>
                    
                    <ul className="dropdown-menu dropdown-menu-end Header-Menu-Options">
                        <li>
                            {theTheme === 'dark' ? (
                                <button className="dropdown-item" type="button" onClick={() => ChangeTheme('light')}>
                                    <i className="bi bi-sun"></i> {translate('labels.header.themes.light')}
                                </button>
                            ) : (
                                <button className="dropdown-item" type="button" onClick={() => ChangeTheme('dark')}>
                                    <i className="bi bi-moon"></i> {translate('labels.header.themes.dark')}
                                </button>
                            )}
                        </li>
                        <li className="dropdown-item Header-Menu-Options-Size">
                            <span className="Header-Menu-Options-Size-Small" onClick={() => ChangeFontSize("SmallFont")}>A</span>
                            <span className="Header-Menu-Options-Size-Medium" onClick={() => ChangeFontSize("MediumFont")}>A</span>
                            <span className="Header-Menu-Options-Size-Big" onClick={() => ChangeFontSize("BigFont")}>A</span>
                        </li>
                        <li>
                            <button className="dropdown-item" type="button" onClick={() => Logout()}><i className="bi bi-logout"></i> {translate('labels.header.logout')}</button>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    )
}

export { InstrumentsHeader };