import { LogErrors } from './../LogErrors'
import { GetErrorMessageTranslated } from './../GetErrorMessageTranslated'

import { ValidatePassword, ValidateSqlInjection } from './Security'

import { Confirms as DLConfirms } from '../DL/Confirms'
import { UpdateSessionInfo } from './../BL/Sessions'


//Agregar multiidioma en mensajes de error


const Confirms = async (session, formData) => {
  try {
    const errors = {}
    const regex = /^[a-záéíóúüñ0-9\s]+$/

    if (formData.name) {
      if (!regex.test(formData.name.toLowerCase())) {
        //errors.name = 'El nombre contiene caracteres inválidos'
        errors.name = GetErrorMessageTranslated('errorMessages.confirm.name.invalidCharacter')
      } else if (await ValidateSqlInjection(formData.name)) {
        errors.name = 'El nombre contiene patrones inválidos'
      }
    } else {
      errors.name = 'El nombre es obligatorio'
    }

    if (formData.lastName) {
      if (!regex.test(formData.lastName.toLowerCase())) {
        errors.lastName = 'Los apellidos contienen caracteres inválidos'
      } else if (await ValidateSqlInjection(formData.lastName)) {
        errors.lastName = 'Los apellidos contienen patrones inválidos'
      }
    } else {
      errors.lastName = 'Los apellidos son obligatorios'
    }

    if (formData.nickname) {
      if (!regex.test(formData.nickname.toLowerCase())) {
        errors.lastName = 'El alias contiene caracteres inválidos'
      } else if (await ValidateSqlInjection(formData.nickname)) {
        errors.nickname = 'El alias contiene patrones inválidos'
      }
    } else {
      errors.nickname = 'El alias es obligatorio'
    }

    if (formData.email) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        errors.email = 'El correo no tiene un formato válido'
      } else if (await ValidateSqlInjection(formData.email)) {
        errors.email = 'El correo contiene patrones inválidos'
      }
    } else {
      errors.email = 'El correo es obligatorio'
    }

    const passwordError = await ValidatePassword(formData.password)

    if (passwordError) {
        errors.password = passwordError
    }

    if (Object.keys(errors).length > 0) {
      return { success: false, errors }
    }

    const response = await DLConfirms(session.token, formData)

    if (response.success) {
      //Actualizar información en sesión local
      session.user.name = formData.name
      session.user.lastName = formData.lastName
      session.user.nickname = formData.nickname
      session.user.email = formData.email
      session.user.isConfirmed = true

      await UpdateSessionInfo(session)

      return { success: true, data: null }
    } else {
      return { success: false, errors: { general: response.message || 'Error en la confirmación' }}
    }
  } catch (error) {
    await LogErrors('Confirms BL', error)
    return { success: false, errors: { general: 'Ocurrió un error inesperado. Por favor, inténtalo más tarde.' }}
  }
}

export { Confirms };








// import { LogErrors } from './../LogErrors'

// //Funciones comunes
// import { ValidateSqlInjection } from './Security'

// import { Confirm as DLConfirm } from './../DL/Confirms'


// const Confirm = async (formData) => {
//     try {
//         // Validación de los datos del formulario
//         const errors = {}

//         if (formData.name) {
//             if (!/^[A-Za-z]+$/.test(formData.name)) {
//                 errors.name = 'El nombre contiene caracteres inválidos'
//                 //errors.name = translate('errorMessages.login.name.invalidCharacter')
//             } else if (ValidateSqlInjection(formData.name)) {
//                 errors.name = 'El nombre que escribiste es inválido'
//             }
//         } else {
//             errors.name = 'El nombre es obligatorio'
//         }

//         if (formData.lastName) {
//             if (!/^[A-Za-z]+$/.test(formData.lastName)) {
//                 errors.lastName = 'El apellido contiene caracteres inválidos'
//             } else if (ValidateSqlInjection(formData.lastName)) {
//                 errors.lastName = 'El apellido que escribiste es inválido'
//             }
//         } else {
//             errors.lastName = 'El apellido es obligatorio'
//         }

//         //Checar la expresión regular
//         if (formData.nickName) {
//             if (!/^[A-Za-z]+$/.test(formData.nickName)) {
//                 errors.nickName = 'El alias contiene caracteres inválidos'
//             } else if (ValidateSqlInjection(formData.nickName)) {
//                 errors.nickName = 'El alias que escribiste es inválido'
//             }
//         } else {
//             errors.nickName = 'El alias es obligatorio'
//         }
        
//         //Checar la expresión regular
//         if (formData.email) {
//             if (!/^[A-Za-z]+$/.test(formData.email)) {
//                 errors.email = 'El correo electrónico contiene caracteres inválidos'
//             } else if (ValidateSqlInjection(formData.email)) {
//                 errors.email = 'El correo electrónico que escribiste es inválido'
//             }
//         } else {
//             errors.email = 'El correo electrónico es obligatorio'
//         }

//         if (formData.user) {
//             if (!/^[A-Za-z0-9]+$/.test(formData.user)) {
//                 errors.user = 'El usuario contiene caracteres inválidos'
//             } else if (ValidateSqlInjection(formData.user)) {
//                 errors.user = 'El usuario que escribiste es inválido'
//             }
//         } else {
//             errors.user = 'El usuario es obligatorio'
//         }

//         if (formData.password) {
//             if (!/^[A-Za-z0-9]+$/.test(formData.password)) {
//                 errors.password = 'La contraseña contiene caracteres inválidos'
//             } else if (ValidateSqlInjection(formData.password)) {
//                 errors.password = 'La contraseña que escribiste es inválida'
//             }
//         } else {
//             errors.password = 'La contraseña es obligatoria'
//         }

//         // Si hay errores, retornar inmediatamente
//         if (Object.keys(errors).length > 0) {
//             return { success: false, errors }
//         }

//         // Llamar a la capa de datos para autenticar al usuario
//         const response = await DLConfirm(formData.name, formData.lastName, formData.nickName, formData.email, formData.user, formData.password)

//         if (response.success) {
//             // Guardar los datos de sesión en sessionStorage
//             sessionStorage.setItem('userData', JSON.stringify(response.data))

//             return { success: true, data: response.data } // Retornar la respuesta exitosa
//         } else {
//             // Manejar errores del backend (por ejemplo, credenciales incorrectas)
//             return { success: false, errors: { general: response.message } }
//         }
//     } catch (error) {
//         await LogErrors('Confirm BL', error)
//         return { success: false, errors: { general: error || 'Ocurrió un error inesperado' } }
//     }
// }

// export { Confirm }