import { useNavigate } from 'react-router-dom'

import { LogErrors } from './../../../utilities/LogErrors'
import { useLanguage } from './../../../utilities/LanguageContext'


//Agregar multiidioma


const Result = () => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()

    
    const ShowMoveIcon = async (e) => {
        try {
            e.target.parentNode.classList.remove('HideMoveButton')
        } catch (error) {
            await LogErrors('ShowMoveIcon - Result', error)
        }
    }
    
    const HideMoveIcon = async (e) => {
        try {
            e.target.parentNode.classList.add('HideMoveButton')
        } catch (error) {
            await LogErrors('HideMoveIcon - Result', error)
        }
    }

    const GoToNextView = async () => {
        try {
            navigate('/results')
        } catch (error) {
            await LogErrors('GoToNextView - Result', error)
        }
    }


    return (
        <div className='Tiles-Courses' onMouseOver={ShowMoveIcon} onMouseOut={HideMoveIcon}>
            <div className='Tile-Info' onClick={() => GoToNextView()}>
                <div className='Tile-ContainerText'>
                    {/* <span className='Tile-Text'>Mis resultados</span> */}
                </div>
            </div>

            <button type='button' className='Tile-Button-Transparent' onClick={() => GoToNextView()}>Ver mis resultados</button> {/*translate('labels.dashboard.tiles.course.goToCourse')*/}
        </div>
    )
}

export { Result };