import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { LogErrors } from './../../utilities/LogErrors'

import { ValidateSession } from './../../utilities/BL/Sessions'
import { GetLearningInstruments } from './../../utilities/BL/Student/Instruments'

//Componentes comunes generales
import { Loading } from './../Common/Loading'
import { InstrumentsHeader } from './../Header/InstrumentsHeader'

//Componentes personalizados del proyecto
import { Teaching } from './Teaching/Teaching'
import { Activity } from './Activity/Activity'
import { Exam } from './Exam/Exam'


const Viewer = () => {
    const navigate = useNavigate()
    
    const [activeComponent, setActiveComponent] = useState('')

    const [isLoading, setIsLoading] = useState(true)
    const [session, setSession] = useState(null)
    const [instrument, setInstrument] = useState({})
    const [theme, setTheme] = useState({})

    const [topicProgress, setTopicProgress] = useState('')
    const [topicName, setTopicName] = useState('') // Estado para el nombre del tema
    //const [headerText, setHeaderText] = useState('')
    const [instrumentData, setInstrumentData] = useState({})


    const LoadPage = async (sessionInfo) => {
        try {
            setTheme(sessionInfo.user.userTheme)

            //await ShowInstrument(sessionInfo)
            setSession({ ...sessionInfo }) // Clonar objeto para forzar re-render
            await ShowInstrument(sessionInfo)
        } catch (error) {
            await LogErrors('LoadPage - Viewer', error)
        }
    }

    const ChangeLearningInstrumentContent = async(sessionInfo) => {
        try {
            //await ShowInstrument(sessionInfo)
            setSession({ ...sessionInfo }) // Clonar objeto para forzar re-render
            await ShowInstrument(sessionInfo)
        } catch (error) {
            await LogErrors('LoadPage - Viewer', error)
        }
    }

    const ShowInstrument = async (sessionInfo) => {
        try {
            setIsLoading(true)

            let response
            let component
            
            switch (sessionInfo.navigation.instrument.type) {
                case 1:
                    //response = await GetExam(sessionInfo)
                    response = {
                        success: true,
                        data: sessionInfo.navigation.instrument
                    }
                    component = 'exam'
                    break
                case 2:
                    response = await GetLearningInstruments(sessionInfo)
                    component = 'teachingMaterial'
                    break
                case 3:
                    response = await GetLearningInstruments(sessionInfo)
                    component = 'activity'
                    break
            }
            
            if (response.success) {
                setInstrument(response.data)
                setActiveComponent(component)
                SetTextOnHeader(sessionInfo)
            } else {
                //Mostrar mensaje de que no se puedo obtener la info o el error
                console.log("No se pudo obtener la info: " + response.message) //Mensaje temporal
            }
        } catch (error) {
            await LogErrors('ShowInstrument - Viewer', error)
        } finally {
            setIsLoading(false)
        }
    }

    const SetTextOnHeader = async (sessionInfo) => {
        try {
            if (sessionInfo.navigation) {
                let tmpInstrumentData = {
                    block: null,
                    topic: null,
                    steps: {
                        start: null,
                        end: null
                    }
                }
                
                // Actualiza el nombre del tema
                setTopicName(sessionInfo.navigation.instrument.name)
                // Obtener el nombre del tema
                tmpInstrumentData.topic = sessionInfo.navigation.instrument.name

                // Encontrar el bloque en el que se encuentra el instrumento actual
                const currentBlock = sessionInfo.courses.blocks.find(block =>
                    block.items.some(item => item.id === sessionInfo.navigation.instrument.id)
                )
                
                if (currentBlock) {
                    // Obtener el nombre del bloque
                    tmpInstrumentData.block = currentBlock.name

                    if (sessionInfo.navigation.instrument.type === 2) {
                        // Filtrar los temas (type: 2) dentro de ese bloque
                        const availableTopics = currentBlock.items.filter(item => item.type === 2)
                    
                        // Encontrar la posición del tema actual dentro del bloque
                        const currentIndex = availableTopics.findIndex(item => item.id === sessionInfo.navigation.instrument.id)
            
                        if (currentIndex !== -1) {
                            // Obtener la posición actual. Ej. 1 de 5
                            tmpInstrumentData.steps.start = currentIndex + 1
                            tmpInstrumentData.steps.end = availableTopics.length
                            // const newTopicProgress = ` - Tema ${currentIndex + 1} de ${availableTopics.length}`
                            // setTopicProgress(newTopicProgress)
                            //setHeaderText(`${sessionInfo.navigation.instrument.name} ${newTopicProgress}`) // Actualizar el Header
                        }
                    }
                }
                
                setInstrumentData(tmpInstrumentData)
            }
        } catch (error) {
            await LogErrors('SetTextOnHeader - Viewer', error)
        }
    }

    useEffect(() => {
        async function Validate() {
            const tmpSession = await ValidateSession()
            setSession(tmpSession.data)
    
            if (tmpSession.success) {
                if (tmpSession.data.user.isConfirmed) {
                    await LoadPage(tmpSession.data)
                } else {
                    navigate('/confirm')
                }
            } else {
                navigate('/')
            }
        }
        Validate()
    },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar


    return (
        isLoading ? (
            <Loading />
        ) : (
            <>
                {/* <Header text={`${session.navigation.instrument.name} ${topicProgress}`} theme={session.user.theme} /> */}
                {/* <Header key={headerText} text={headerText} theme={session.user.theme} /> */}
                {/* <Header key={topicName} text={`${topicName} ${topicProgress}`} theme={session.user.userTheme} /> */}
                {/* <Header text={`${topicName} ${topicProgress}`} theme={theme} /> */}
                <InstrumentsHeader instrumentData={instrumentData} theme={theme} />

                {activeComponent.toLowerCase() === 'exam'.toLowerCase() && <Exam session={session} instrument={instrument} />}
                {activeComponent.toLowerCase() === 'teachingMaterial'.toLowerCase() && <Teaching session={session} setSession={setSession} instrument={instrument} changeLearningInstrumentContent={ChangeLearningInstrumentContent} topicName={topicName} />}
                {activeComponent.toLowerCase() === 'activity'.toLowerCase() && <Activity activityName={session.navigation.instrument.name} instrument={instrument} />}
            </>
        )
    )
}

export { Viewer };