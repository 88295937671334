import { LogErrors } from './../LogErrors'

import { UpdateSessionInfo } from './../DL/Sessions'


//----- Ztudiante -----//

const SaveCourseToShow = async (session, element, goToQuickAccessInstrument = false) => {
    try {
        session.navigation.course = {
            "id": element.id,
            "name": element.name,
            "type": element.type,
            "level": element.level,
            "goToQuickAccessInstrument": goToQuickAccessInstrument
        }
        // "course": {
        // }
        
        const response = await UpdateSessionInfo(session)

        if (response.success) {
            return { success: true, data: response.data }
        } else {
            return { success: false, message: response.message }
        }
    } catch (error) {
        await LogErrors('SaveCourseToShow - Navigation BL', error)
        return { success: false, message: '' }
    }
}

const ResetCourseToShow = async (session) => {
    try {
        if (session.navigation === undefined) {
            return { success: true, data: session }
        } else {
            session.navigation = null
            
            const response = await UpdateSessionInfo(session)
            
            if (response.success) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response.message }
            }
        }
    } catch (error) {
        await LogErrors('ResetCourseToShow - Navigation BL', error)
        return { success: false, message: '' }
    }
}

const SaveInstrumentToShow = async (session, instrument) => {
    try {
        session.navigation.instrument = {
            "id": instrument.id,
            "name": instrument.name,
            "type": instrument.type
        }
        
        const response = await UpdateSessionInfo(session)

        if (response.success) {
            return { success: true, data: response.data }
        } else {
            return { success: false, message: response.message }
        }
    } catch (error) {
        await LogErrors('SaveInstrumentToShow - Navigation BL', error)
        return { success: false, message: '' }
    }
}

const ResetInstrumentToShow = async (session) => {
    try {
        if (session.navigation.instrument === undefined) {
            return { success: true, data: session }
        } else {
            session.navigation.instrument = null
            
            const response = await UpdateSessionInfo(session)

            if (response.success) {
                return { success: true, data: response.data }
            } else {
                return { success: false, message: response.message }
            }
        }
    } catch (error) {
        await LogErrors('ResetInstrumentToShow - Navigation BL', error)
        return { success: false, message: '' }
    }
}

// const SaveLearningInstrumentsToShow = async (session, learningInstrumentId) => {
//     try {
//         session.navigation.instruments = {
//             "item": {
//                 "id": learningInstrumentId
//             }
//         }
        
//         const response = await UpdateSessionInfo(session)

//         if (response.success) {
//             return { success: true, data: response.data }
//         } else {
//             return { success: false, message: response.message }
//         }
//     } catch (error) {
//         await LogErrors('SaveLearningInstrumentsToShow - Navigation BL', error)
//         return { success: false, message: '' }
//     }
// }

// const SaveActivityToShow = async (session, idActivity) => {
//     try {
//         session.navigation.activity = {
//             "item": {
//                 "id": idActivity
//             }
//         }
        
//         const response = await UpdateSessionInfo(session)

//         if (response.success) {
//             return { success: true, data: response.data }
//         } else {
//             return { success: false, message: response.message }
//         }
//     } catch (error) {
//         await LogErrors('SaveActivityToShow - Navigation BL', error)
//         return { success: false, message: '' }
//     }
// }

const SaveCenterToShow = async (session, idCenter) => {
    try {
        session.navigation = {
            "center": {
                "id": idCenter
            }
        }
        
        const response = await UpdateSessionInfo(session)

        if (response.success) {
            return { success: true, data: response.data }
        } else {
            return { success: false, message: response.message }
        }
    } catch (error) {
        await LogErrors('SaveCenterToShow - Navigation BL', error)
        return { success: false, message: '' }
    }
}

//----- Ztudiante -----//

export { SaveCourseToShow, ResetCourseToShow, SaveInstrumentToShow, ResetInstrumentToShow, SaveCenterToShow };