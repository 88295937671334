import { LogErrors } from './../LogErrors'


/**
 * 
 * @param {string} user 
 * @param {string} password 
 * @returns {Object} Resultado del proceso { success: boolean, errors: Object, data: Object }
 */
const Login = async (user, password) => {
    try {
        const dataToSend = {
            username: user,
            password: password
        }

        const response = await fetch("https://ztudia.xyz/api/Api/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSend)
        })

        if (!response.ok) {
            // Manejar errores HTTP
            const errorData = await response.json()
            //return { success: false, message: errorData.message || 'Error de autenticación' }
            return { success: false, message: errorData.message }
        }
        
        const data = await response.json()
    
        // Retornar la respuesta exitosa
        return { success: true, data: data }

        // return {
        //     success: true,
        //     data: {
        //         "token": "abc123xyzamarv",
        //         "user": {
        //             "name": "Juan",
        //             "lastName": "Pérez",
        //             "nickname": "",
        //             "email": "",
        //             "theme": "light",
        //             "isConfirmed": true,
        //             "centers": [
        //                 {
        //                     "id": "1",
        //                     "name": "Colegio Carmelo Reyna",
        //                     "isFavorite": true,
        //                     "roles": [
        //                         {
        //                             "id": 1,
        //                             "isFavorite": true
        //                         }
        //                     ]
        //                 }
        //             ]
        //         }
        //     }
        // }




        //roles
        // ,
        // {
        //     "id": 2,
        //     "isFavorite": false
        // }

        // ,
        // {
        //     "id": "2",
        //     "name": "Colegio Francis",
        //     "isFavorite": false,
        //     "roles": [
        //         {
        //             "id": 3,
        //             "isFavorite": true
        //         }
        //     ]
        // }
        
    } catch (error) {
        //console.error('Error en Login DL:', error);
        //return { success: false, message: error || 'Error al conectar con el servidor' }
        await LogErrors('Login - Sessions DL', error)
        //return { success: false, message: 'errorNetwork' } // Código genérico para errores de red
        return { success: false, message: 2 }
    }
}

/**
 * @param {string} token
 * @returns {Object} Resultado del proceso { success: boolean, errors: Object, data: Object }
 */
const Logout = async (token) => {
    try {
        const response = await fetch("https://ztudia.xyz/api/Api/closeSession", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
        
        if (!response.ok) {
            // Manejar errores HTTP
            const errorData = await response.json()
            //return { success: false, message: errorData.message || 'Error de autenticación' }
            return { success: false, message: errorData.message } //errorCode
        }
        
        // Retornar la respuesta exitosa
        return { success: true, data: null }
    } catch (error) {
        await LogErrors('Logout - Sessions DL', error)
        return { success: false, message: '' }
    }
}

/**
 * Función que valida si la sesión sigue activa
 * @param {string} token
 * @returns {Object} Resultado del proceso { success: boolean, errors: Object, data: Object }
 */
const ValidateSession = async (token) => {
    try {
        const response = await fetch("https://ztudia.xyz/api/Api/ValidateSession", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
        
        if (!response.ok) {
            // Manejar errores HTTP
            const errorData = await response.json()
            //return { success: false, message: errorData.message || 'Error de autenticación' }
            return { success: false, message: errorData.message } //errorCode
        }
    
        //const data = await response.json()
    
        // Retornar la respuesta exitosa
        return { success: true, data: {} }

        // const response = await fetch("http://191.101.14.23/api/Gateway/validate", {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({ token })
        // })

        // if (!response.ok) {
        //     // Manejar errores HTTP
        //     const errorData = await response.json()
        //     //return { success: false, message: errorData.message || 'Error de autenticación' }
        //     return { success: false, message: errorData.errorCode }
        // }
    
        // const data = await response.json()
    
        // Retornar la respuesta exitosa
        //return { success: true }
    } catch (error) {
        await LogErrors('ValidateSession - Session DL', error)
        return { success: false, message: '' }
    }
}

/**
 * @param {Array} sessionInfo 
 * @returns {Object} Resultado del proceso { success: boolean, errors: Object, data: Object }
 */
const UpdateSessionInfo = async (sessionInfo) => {
    try {
        await sessionStorage.setItem('userData', JSON.stringify(sessionInfo))

        return { success: true, data: sessionInfo }
    } catch (error) {
        await LogErrors('UpdateSessionInfo - Session DL', error)
        return { success: false, message: '' }
    }
}

const DeleteSessionInfo = async () => {
    try {
        await sessionStorage.removeItem('userData')
        await sessionStorage.removeItem('settings')

        return { sucess: true }
    } catch (error) {
        await LogErrors('DeleteSessionInfo - Session DL', error)
        return { success: false, message: '' }
    }
}

export { Login, Logout, ValidateSession, UpdateSessionInfo, DeleteSessionInfo };