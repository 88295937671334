import { LogErrors } from './../LogErrors'


/**
 * Realiza la llamada a la API para registrar los datos de confirmación.
 * @param {Object} formData Información del formulario { name, lastName, alias, email, password }
 * @returns {Object} Resultado del proceso { success: boolean, data: Object, message: string }
 */
const Confirms = async (token, formData) => {
  try {
    const dataToSend = {
      user: {
        name: formData.name,
        lastName: formData.lastName,
        nickname: formData.nickname,
        email: formData.email,
        password: formData.password
      }
    }
    
    const response = await fetch('https://ztudia.xyz/api/Api/Confirm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(dataToSend),
    })
    
    if (!response.ok) {
      // Manejar errores HTTP
      const errorData = await response.json()
      return { success: false, message: errorData.message } //errorCode
    }
    
    // Retornar la respuesta exitosa
    return { success: true, data: null }
  } catch (error) {
    await LogErrors('Confirms DL', error)
    //return { success: false, message: 'errorNetwork' } // Código genérico para errores de red
    return { success: false, message: 2 }
  }
}

export { Confirms };