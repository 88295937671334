import { LogErrors } from './../LogErrors'

import { Login as DLLogin, Logout as DLLogout, ValidateSession as DLValidateSession, UpdateSessionInfo as DLUpdateSessionInfo, DeleteSessionInfo as DLDeleteSessionInfo } from './../DL/Sessions'


/**
 * 
 * @param {string} user 
 * @param {string} password 
 * @returns {Object} Resultado del proceso { success: boolean, errors: Object, data: Object }
 */
const Login = async (user, password) => {
    try {
        const response = await DLLogin(user, password)
        
        if (!response.success) {
            //No fue posible iniciar sesión
            return { success: false, message: response.message }
        }

        sessionStorage.setItem('userData', JSON.stringify(response.data))
    
        // Retornar la respuesta exitosa
        return { success: true, data: response.data }
    } catch (error) {
        await LogErrors('Login - Sessions BL', error)
        //return { success: false, message: 'errorNetwork' } // Código genérico para errores de red
        return { success: false, message: 2 }
    }
}

/**
 * @param {string} token
 * @returns {Object} Resultado del proceso { success: boolean, errors: Object, data: Object }
 */
const Logout = async (token) => {
    try {
        const response = await DLLogout(token)
        
        if (!response.success) {
            return { success: false, message: response.message }
        }

        if (sessionStorage.getItem('userData')) {
            sessionStorage.removeItem('userData')
        }

        return { success: true, data: null }
    } catch (error) {
        await LogErrors('Logout - Sessions BL', error)
        return { success: false, message: '' }
    }
}

/**
 * Función que valida si la sesión sigue activa
 * @returns {Object} Resultado del proceso { success: boolean, errors: Object, data: Object }
 */
const ValidateSession = async () => {
    try {
        //let userData = {}
        
        //Validar que exista la info del usuario en el session storage
        if (!sessionStorage.getItem('userData')) {
            return { success: false, message: '' } //En este caso borraron los datos; así que le muestro mensaje indicando que su sesión ha expirado.
        }
        
        //Obtener la info del usuario desde el session storage
        const userData = await JSON.parse(sessionStorage.getItem('userData'))
        
        //Comprobar varias propiedades para ver si está correcta la info del usuario
        if (userData.token === null || userData.token === '' || userData.user === null || userData.user.name === '') {
            sessionStorage.removeItem('userData')
            return { success: false, message: '' } //Mensaje indicando que su sesión ha expirado.
        }

        const response = await DLValidateSession(userData.token)
        
        if (response.success) {
            return { success: true, data: userData }
        } else {
            sessionStorage.removeItem('userData')
            return { success: false, message: response.message } //Mensaje indicando que su sesión ha expirado.
        }
    } catch (error) {
        await LogErrors('ValidateSession - Sessions BL', error)
        return { success: false, message: '' }
    }
}

/**
 * @param {Array} sessionInfo 
 * @returns {Object} Resultado del proceso { success: boolean, errors: Object, data: Object }
 */
const UpdateSessionInfo = async (sessionInfo) => {
    try {
        const response = await DLUpdateSessionInfo(sessionInfo)
        
        if (response.success) {
            return { success: true, data: response.data }
        } else {
            return { success: false, message: response.message }
        }
    } catch (error) {
        await LogErrors('UpdateSessionInfo - Sessions BL', error)
        return { success: false, message: '' }
    }
}

const DeleteSessionInfo = async () => {
    try {
        const response = await DLDeleteSessionInfo()

        if (response.success) {
            return { success: true, data: response.data }
        } else {
            return { success: false, message: response.message }
        }
    } catch (error) {
        await LogErrors('DeleteSessionInfo - Sessions BL', error)
        return { success: false, message: '' }
    }
}

/**
 * Función que devuelve el token almacenado en la información de la sesión
 * @returns {Object} Resultado del proceso { success: boolean, errors: Object, data: Object }
 */
const GetSessionToken = async () => {
    try {
        //Validar que exista la info del usuario en el session storage
        if (!sessionStorage.getItem('userData')) {
            return { success: false, message: '' } //En este caso borraron los datos; así que le muestro mensaje indicando que su sesión ha expirado.
        }
        
        //Obtener la info del usuario desde el session storage
        const userData = await JSON.parse(sessionStorage.getItem('userData'))
        
        //Comprobar varias propiedades para ver si está correcta la info del usuario
        if (userData.token === null || userData.token === '') {
            return { success: false, message: '' } //Mensaje indicando que su sesión ha expirado.
        }

        return { success: true, data: userData.token }
    } catch (error) {
        await LogErrors('GetSessionToken - Sessions BL', error)
        return { success: false, message: '' }
    }
}

export { Login, Logout, ValidateSession, UpdateSessionInfo, DeleteSessionInfo, GetSessionToken };