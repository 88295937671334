import { LogErrors } from './../../LogErrors'


/**
 * Realiza la llamada a la API para obtener los cursos del usuario
 * @returns {Object} Resultado del proceso { success: boolean, data: Object, message: string }
 */
const GetCourses = async (token, centerSelected, roleSelected) => {
  try {
    const dataToSend = {
      "user": {
        "center": {
          "id": centerSelected
        },
        "role": {
          "id": roleSelected
        }
      }
    }

    const response = await fetch('https://ztudia.xyz/api/Api/ZtudianteMyCourses', { // Reemplaza "X" con la URL de tu API
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(dataToSend),
    })

    if (!response.ok) {
      // Manejar errores HTTP
      const errorData = await response.json()
      return { success: false, message: errorData.errorCode }
    }

    const data = await response.json()

    // Retornar la respuesta exitosa
    return { success: true, data: data }
  } catch (error) {
    await LogErrors('GetCourses - Courses DL', error)
    //return { success: false, message: 'errorNetwork' } // Código genérico para errores de red
    return { success: false, message: 2 }
  }
}

const GetChoosenCourse = async (token, idCourse) => {
  try {
    const dataToSend = {
      "course": {
        "id": idCourse
      }
    }
    // Llamado a la API

    const response = await fetch("https://ztudia.xyz/api/Api/ChosenCourse", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(dataToSend)
    })

    if (!response.ok) {
        // Manejar errores HTTP
        const errorData = await response.json()
        //return { success: false, message: errorData.message || 'Error de autenticación' }
        return { success: false, message: errorData.message } //errorData.errorCode
    }

    const data = await response.json()

    // Retornar la respuesta exitosa
    return { success: true, data: data }
  } catch (error) {
    await LogErrors('GetChoosenCourse - Courses DL', error)
    return { success: false, message: 2 }
  }
}

export { GetCourses, GetChoosenCourse };