import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { useLanguage } from "../../utilities/LanguageContext"

//Componentes comunes generales
import { Header } from './../Header/Header'
import { Loading } from './../Common/Loading'
import { LogErrors } from './../../utilities/LogErrors'
import { ValidateSession } from './../../utilities/BL/Sessions'
import { GoToTop } from './../Common/GoToTop'
import { GoToBack } from './../Common/GoToBack'
//import { GetDataFromTheChosenCourse } from "../../utilities/BL/Student/Courses"

import { CourseData } from './CourseData'

//Funciones personalizadas del proyecto
//import { GetExamResult } from './../../utilities/BL/Students'
//import { QuestionDrafting } from './QuestionDrafting'


//Agregar multiidioma


const Results = () => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()

    const [session, setSession] = useState({})
    const [theme, setTheme] = useState({})

    const [isLoading, setIsLoading] = useState(true)
    const [evaluations, setEvaluations] = useState(null)

    
    const LoadPage = async (sessionInfo) => {
        try {
            setTheme(sessionInfo.user.userTheme)
            setEvaluations(sessionInfo.evaluations)
        } catch (error) {
            await LogErrors('LoadPage Results', error)
        } finally {
            setIsLoading(false)
        }
    }

    /**
     * Función para obtener la configuración, una vez que termine de cargarse el componente
     */
    useEffect(() => {
        async function Validate() {
            const tmpSession = await ValidateSession()
            setSession(tmpSession.data)
            
            if (tmpSession.success) {
                if (tmpSession.data.user.isConfirmed) {
                    await LoadPage(tmpSession.data)
                } else {
                    navigate('/confirm')
                }
            } else {
                navigate('/')
            }
        }
        Validate()
    }, []) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar
    

    return (
        <>    
            <Header theme={theme} />

            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <main className="container Results">
                        <GoToBack url={'/dashboard'} />

                        {evaluations ? (
                            <>
                                {evaluations.length === 0 ? (
                                    <p className="Results-Course">Aún no tienes resultados.</p>
                                ) : (
                                    evaluations.map((evaluation, index) => (
                                        index === 0 && (
                                            <div key={index}>
                                                <p className="Results-Title">Resultados de: {evaluation.name}</p>
                                                {/* <p className="Results-Info">Certificación: Hojas de cálculo</p> */}
                                                <CourseData session={session} evaluation={evaluation} />
                                                <p className="Results-Info">{`Resultado: ${evaluation.score}`}</p>
                                                {/* <p className="Results-Info">{`Tiempo: ${evaluation.totalTime / 60} minutos`}</p> */}
                                                <p className="Results-Info">{`Total de preguntas: ${evaluation.totalQuestions}`}</p>
                                                <p className="Results-Info">{`Preguntas correctas: ${evaluation.correct}`}</p>
                                                <p className="Results-Info Last-Child">{`Preguntas incorrectas: ${evaluation.incorrect}`}</p>
                                            
                                                {evaluation.questions.map((question, subindex) => (
                                                    <div key={subindex} className={`Results-Question ${question.result === 0 ? 'Results-Question-Bad' : 'Results-Question-Good'}`}>
                                                        <p dangerouslySetInnerHTML={{ __html: question.instructions[0] }} />
    
                                                        {question.result === 0 ? (
                                                            <p className="Results-Question-Detail-Bad">{question.analysis}</p>
                                                        ) : (
                                                            <p className="Results-Question-Detail-Good">¡Correcto!</p>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    ))
                                )}
                            </>
                        ) : (
                            <p className="Results-Course">No se pudieron obtener los resultados de tus evaluaciones. Inténtalo nuevamente o más tarde.</p>
                        )}
                    </main>

                    <GoToTop />
                </>
            )}
        </>
    )
}

export { Results };





// result ? (
//     result.message === undefined ? (
//         <main className="Container Results">
//             <button type='button' className='Button-Return' onClick={() => navigate('/dashboard')}><i className="bi bi-arrow-left"></i></button>
            
//             <p className="Results-Course">Resultados de: Examen Demo</p>
//             <p className="Results-Score">{`Resultado: ${result.score} puntos`}</p>
//             <p className="Results-TotalQuestions">{`Total de preguntas: ${result.totalQuestions}`}</p>
            
//             {result.questionsResponse.map((question, index) => (
//                 <div key={index} className={`Results-Question ${question.answer === 0 ? 'Results-Question-Bad' : 'Results-Question-Good'}`}>
//                     {/* <p>Pregunta: {question.drafting.replace('#','')}</p> */}
//                     <QuestionDrafting drafting={question.drafting} />

//                     {question.answer === 0 ? (
//                         <p className="Results-Question-Detail-Bad">{question.detail}</p>
//                     ) : (
//                         <p className="Results-Question-Detail-Good">¡Correcto!</p>
//                     )}
//                 </div>
//             ))}
//         </main>
//     ) : (
//         <main className="Container Results">
//             <button type='button' className='Button-Return' onClick={() => navigate('/dashboard')}><i className="bi bi-arrow-left"></i></button>

//             {result.message === '' ? (
//                 <p className="Results-Course">No se pudo obtener el resultado del examen. Intenta nuevamente o intenta más tarde.</p>
//             ) : (
//                 <p className="Results-Course">{result.message}</p>
//             )}
//         </main>
//     )
// ) : (
//     <main className="Container Results">
//         <button type='button' className='Button-Return' onClick={() => navigate('/dashboard')}><i className="bi bi-arrow-left"></i></button>

//         <p className="Results-Course">No se pudieron obtener los resultados de tu examen. Inténtalo nuevamente o más tarde.</p>
//     </main>
// )