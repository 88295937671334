import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { LogErrors } from './../../../utilities/LogErrors'
import { useLanguage } from './../../../utilities/LanguageContext'


import { GetExam, GetInstrumentName as DLGetInstrumentName, GetBlockName as DLGetBlockName } from './../../../utilities/BL/Student/Instruments'
import { Logout, ValidateSession } from './../../../utilities/BL/Sessions'

import { GoToBack } from './../../Common/GoToBack'


//Agregar multiidioma


const Exam = ({ session, instrument }) => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()

    const [examName, setExamName] = useState('')
    const [blockName, setBlockName] = useState('')
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    //const [appNotInstalled, setAppNotInstalled] = useState(null)
    //const [errorMessage, setErrorMessage] = useState('')
    const [loginView, setLoginView] = useState(false)


    // const GoToLink = async (url) => {
    //     try {
    //         return new Promise((resolve) => {
    //             const hiddenIframe = document.createElement('iframe')
    //             hiddenIframe.style.display = 'none'
    //             document.body.appendChild(hiddenIframe)
    //             hiddenIframe.src = url
    
    //             // Tiempo de espera para detectar si se ejecuta la app (1000 ms)
    //             setTimeout(() => {
    //                 document.body.removeChild(hiddenIframe)
    //                 resolve(false) // Si después del tiempo de espera no hay respuesta, asumimos que falló
    //             }, 1000)
    //         })
    //     } catch (error) {
    //         await LogErrors('GoToLink - Viewer Exam', error)
    //         return false
    //     }
    // }

    const GoToExam = async () => {
        try {
            setIsButtonLoading(true)

            const tmpSession = await ValidateSession()
            
            if (tmpSession.success) {
                if (tmpSession.data.user.isConfirmed) {
                    const response = await GetExam(session)

                    if (response.success) {
                        // const responseGoToLink = await GoToLink("ztudia://" + response.data.token)

                        // if (responseGoToLink === true) {
                        //     await Logout(session.token)
                        //     setIsButtonLoading(false)
                        // } else {
                        //     setAppNotInstalled(true)
                        // }

                        //Iniciar sesión
                        setLoginView(true)
                        await Logout(tmpSession.data.token)
                        window.location.href = 'ztudia://' + response.data.token
                    } else {
                        //console.log(response.message)
                        //<p className='Exams-Container-Notes'>{translate('labels.viewer.exam.note')}</p>
                        //setErrorMessage(response.message)
                    }
                } else {
                    navigate('/confirm')
                }
            } else {
                navigate('/')
            }
        } catch (error) {
            await LogErrors('GoToExam - Viewer Exam', error)
        }
    }

    const GetInstrumentName = async () => {
        try {
            const response = await DLGetInstrumentName(session)

            if (response.success) {
                setExamName(response.data)
            } else {
                console.log(response.message)
            }
        } catch (error) {
            await LogErrors('GetInstrumentName - Viewer Exam', error)
        }
    }

    const GetBlockName = async () => {
        try {
            const response = await DLGetBlockName(session, instrument)

            if (response.success) {
                setBlockName(response.data)
            } else {
                console.log(response.message)
            }
        } catch (error) {
            await LogErrors('GetBlockName - Viewer Exam', error)
        }
    }

    const GoToLogin = async () => {
        try {
            navigate('/')
        } catch (error) {
            await LogErrors('GoToLogin - Viewer Exam', error)
        }
    }

    const LoadPage = async () => {
        try {
            await GetInstrumentName()
            await GetBlockName()
        } catch (error) {
            await LogErrors('LoadPage - Viewer Exam', error)
        }
    }

    useEffect(() => {
        LoadPage()
    }, [])

    
    return (
        <main className='container Container-FullHeight Exams'>
            {/* <div className="Exams-Container">
                <> */}
                    {loginView ? (
                        <div className="Exams-Container">
                            <div className='Exams-Container-Data'>
                                <p className='Exams-Container-Title'>Sesión finalizada</p>
                                <p className='Exams-Container-Text'>Se ha cerrado tu sesión. Haz clic en el botón para ir a iniciar tu sesión.</p>
                                <button type='button' className='Exams-Container-Buttons' onClick={GoToLogin}>Ir a iniciar sesión</button>
                            </div>
                        </div>
                    ) : (
                        <div className="Exams-Container">
                            <GoToBack url={'/course'} />

                            <div className='Exams-Container__Evaluation'>
                                <div className='Exams-Container-Data'>
                                    <i className="bi bi-file-earmark-spreadsheet Exams-Container-Icon"></i>
                                    <p className='Exams-Container-Title'>{translate('labels.viewer.exam.title')}</p>
                                    <p className='Exams-Container-Text'>{translate('labels.viewer.exam.block')} {blockName}</p>
                                    <p className='Exams-Container-Text'>{translate('labels.viewer.exam.name')} {examName}</p>
                                </div>
                                
                                <div className='Exams-Container-Instructions'>
                                    <div className='Exams-Container-Instructions-Subcontainer'>
                                        {/* <p className='Exams-Container-Text Exams-Container-Instructions'>{translate('labels.viewer.exam.instruction')}</p> */}
                                        <p className='Exams-Container-Instructions-Top'>Inicia tu examen en 2 sencillos pasos:</p>
                                        <p className=''>1.- Instala la aplicación <span className='Exams-Container-Instructions-Highlight'>ZtudiaApp</span> en tu equipo (PC o Laptop)</p>
                                        <p className=''>2.- Haz clic en el botón <span className='Exams-Container-Instructions-Highlight'>Ir al examen</span> desde tu equipo (PC o Laptop)</p>
                                    </div>

                                    {isButtonLoading ? (
                                        <button type='button' className='Exams-Container-Buttons Exams-Container-Buttons-Disabled'>{translate('labels.viewer.exam.loadingSubmit')}</button>
                                    ) : (
                                        <button type='button' className='Exams-Container-Buttons' onClick={GoToExam}>{translate('labels.viewer.exam.submit')}</button>
                                    )}

                                    {/* {errorMessage ? (
                                        errorMessage === '' ? (
                                            <p className='Exams-Container-Notes'>Hubo un error y no ha sido posible ejecutar tu examen. Inténtalo nuevamente o más tarde.</p>
                                        ) : (
                                            <p className='Exams-Container-Notes'>{errorMessage}</p>
                                        )
                                    ) : (
                                    )} */}
                                    <p className='Exams-Container-Notes'>{translate('labels.viewer.exam.note')}</p>
                                </div>
                            </div>

                            <div className='Exams-Container__Message'>
                                <div className='Exams-Container-Instructions'>
                                    <div className='Exams-Container-Instructions-Subcontainer'>
                                        <p className='Exams-Container-Instructions-Top'>Inicia tu examen en 2 sencillos pasos:</p>
                                        <p className=''>1.- Instala la aplicación <span className='Exams-Container-Instructions-Highlight'>ZtudiaApp</span> en tu equipo (PC o Laptop)</p>
                                        <p className=''>2.- Haz clic en el botón <span className='Exams-Container-Instructions-Highlight'>Ir al examen</span> desde tu equipo (PC o Laptop)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                {/* </> */}

                {/* {appNotInstalled ? (
                    <>
                        <p className='Exams-Container-Title'>{translate('labels.viewer.exam.titleAppNotInstalled')}</p>
                        <p className='Exams-Container-Text'>{translate('labels.viewer.exam.textAppNotInstalled')}</p>
                        <button type='button' className='Exams-Container-Buttons' onClick={() => navigate('/course')}>{translate('labels.viewer.exam.returnButton')}</button>
                    </>
                ) : (
                )} */}
            {/* </div> */}
        </main>
    )
}

export { Exam };







// const GoToLink = async (url) => {
//     try {
//         window.location.href = url
//         return true
//     } catch (error) {
//         await LogErrors('GoToLink', 'No se pudo ejecutar el examen. Asegúrate de tener instalada correctamente la aplicación necesaria.')
//         return false
//     }
// }

// const GoToExam = async () => {
//     try {
//         const response = await GetExam(session)

//         if (response.success) {
//             const responseGoToLink = await GoToLink("myapp://" + response.data.xyz)

//             if (responseGoToLink === true) {
//                 await Logout(session.abc)
//             } else {
//                 console.log('No se pudo ejecutar el examen. Asegúrate de tener instalada correctamente la aplicación necesaria.')
//             }
//         } else {
//             console.log(response.message)
//         }
//     } catch (error) {
//         await LogErrors('GoToExam', error)
//     }
// }