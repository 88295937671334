import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useLanguage } from './../../utilities/LanguageContext'
import { LogErrors } from './../../utilities/LogErrors'

import { ValidateSession } from './../../utilities/BL/Sessions'

import { GetDataFromTheChosenCourse } from './../../utilities/BL/Student/Courses'


//Agregar multiidioma


const CourseData = ({ evaluation }) => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto
    const navigate = useNavigate()

    const [courseData, setCourseData] = useState(null)

    
    const LoadPage = async (sessionInfo) => {
        try {
            const response = await GetDataFromTheChosenCourse(sessionInfo, evaluation)
            
            if (response.success) {
                setCourseData(response.data)
            }
        } catch (error) {
            await LogErrors('LoadPage - Course CourseData', error)
        }
    }

    useEffect(() => {
        async function Validate() {
            const tmpSession = await ValidateSession()
            
            if (tmpSession.success) {
                if (tmpSession.data.user.isConfirmed) {
                    await LoadPage(tmpSession.data)
                } else {
                    navigate('/confirm')
                }
            } else {
                navigate('/')
            }
        }
        Validate()
    },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar


    return (
        courseData ? (
            <p className="Results-Info">{`Curso: ${courseData.name} nivel ${courseData.level === 1 ? translate('labels.course.levels.one') : translate('labels.course.levels.two')} ${courseData.type === 1 ? translate('labels.dashboard.tiles.examType.certification') : translate('labels.dashboard.tiles.examType.custom')}`}</p>
        ) : (
            <></>
        )
    )
}

export { CourseData };