import { LogErrors } from './../../LogErrors'


const GetResults = async (token) => {
    try {
        const response = await fetch("https://ztudia.xyz/api/Api/GetEvaluationsResults", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })

        if (!response.ok) {
            // Manejar errores HTTP
            const errorData = await response.json()
            //return { success: false, message: errorData.message || 'Error de autenticación' }
            return { success: false, message: errorData.message } //errorCode
        }

        const data = await response.json()
        
        // Retornar la respuesta exitosa
        return { success: true, data: data }
    } catch (error) {
        await LogErrors('GetResults DL Results', error)
    }
}

export { GetResults };