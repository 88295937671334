import { useState, useEffect } from 'react'

//Funciones comunes
import { LogErrors } from './../../../../utilities/LogErrors'
import { useLanguage } from './../../../../utilities/LanguageContext'

//Funciones personalizadas
import { SavePreferenceVideo } from './../../../../utilities/BL/Student/Settings'

import { VerticalVideo } from './VerticalVideo'
import { HorizontalVideo } from './HorizontalVideo'

//Agregar el multiidioma en mensajes y errores


const Videos = ({ session, setSession, instrument }) => {
    const { translate } = useLanguage() // Obtén la función de traducción desde el contexto

    const [showVertical, setShowVertical] = useState(null)
    const [hasVideo, setHasVideo] = useState(null)
    const [bothVideoFormatsExist, setBothVideoFormatsExist] = useState(false)


    const ShowVideo = async (sessionInfo) => {
        try {
            let horizontal = false
            let vertical = false
            let existShowVerticalOption = false

            //Revisar si existe el video horizontal
            if (instrument.video.file === undefined || instrument.video.file === '') {
                horizontal = false
            } else {
                horizontal = true
            }

            //Revisar si existe el video vertical
            if (instrument.video.verticalFile === undefined || instrument.video.verticalFile === '') {
                vertical = false
            } else {
                vertical = true
            }

            //Revisar si existe el video horizontal y vertical
            if (horizontal || vertical) {
                setHasVideo(true)

                if (horizontal && vertical) {
                    setBothVideoFormatsExist(true)
                }
            } else{
                setHasVideo(false)
                return
            }

            //Checar si existe la propiedad que indica qué tipo de video prefiere el usuario
            if (sessionInfo.settings === undefined || sessionInfo.settings.video === undefined || sessionInfo.settings.video.showVertical === undefined) {
                existShowVerticalOption = false
            } else {
                existShowVerticalOption = true
            }

            if (existShowVerticalOption) {
                if (sessionInfo.settings.video.showVertical) {
                    if (vertical) {
                        setShowVertical(true)
                    } else if (horizontal) {
                        setShowVertical(false)
                    }
                } else {
                    if (horizontal) {
                        setShowVertical(false)
                    } else if (vertical) {
                        setShowVertical(true)
                    }
                }
            } else {
                if (horizontal) {
                    setShowVertical(false)
                } else if (vertical) {
                    setShowVertical(true)
                }
            }
        } catch (error) {
            await LogErrors('ShowVideo - Videos', error)
        }
    }

    const SetPreferenceVideo = async (orientation = '') => {
        try {
            let showVertical = false

            if (orientation.toLowerCase() === 'v') {
                showVertical = true
            }

            const response = await SavePreferenceVideo(session, showVertical)

            if (response.success) {
                setSession(response.data)
                await ShowVideo(response.data)
            } else {
                //Mostrar mensaje de que no se puedo obtener la info o el error
                console.log(response.message) //Mensaje temporal
            }
        } catch (error) {
            await LogErrors('SetPreferenceVideo - Videos', error)
        }
    }

    const LoadPage = async () => {
        try {
            await ShowVideo(session)
        } catch (error) {
            await LogErrors('LoadPage - Videos', error)
        }
    }

    useEffect(() => {
        LoadPage()
    },[]) // El array vacío [] asegura que useEffect se ejecute solo una vez, al montar


    return (
        <div className="Videos">
            {hasVideo ? (
                <>
                    {bothVideoFormatsExist && (
                        <div className='container Videos-Buttons-Container'>
                            <button className={`Videos-Buttons ${!showVertical && ' Videos-Buttons-Active'}`} onClick={() => SetPreferenceVideo()}><i className="bi bi-play-btn"></i></button>
                            <button className={`Videos-Buttons ${showVertical && ' Videos-Buttons-Active'}`} onClick={() => SetPreferenceVideo('v')}><i className="bi bi-file-play"></i></button>
                        </div>
                    )}

                    {instrument.video.subtitles ? (
                        showVertical ? (
                            <VerticalVideo instrument={instrument} />
                        ) : (
                            <HorizontalVideo instrument={instrument} />
                        )
                    ) : (
                        <video
                            className='Video-Video'
                            src={showVertical ? instrument.video.verticalFile : instrument.video.file}
                            controls
                        >
                            {translate('labels.viewer.videos.tagLegend')}
                        </video>
                    )}
                </>
            ) : (
                <p>{translate('labels.viewer.videos.empty')}</p>
            )}
        </div>
    )
}

export { Videos };